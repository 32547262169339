import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import FileList from '../Attachments/FileList';
import {Attributes, AdjuntoToggle} from '../../components';
import TabPanel from './TabPanel';
import WorkOrderTable from '../WorkOrder/WorkOrderTable';
import TasksTable from '../Task/TasksTable';
import StockPlanningShowEdit from '../Stock/StockPlanningShowEdit';
import MeasurementTable from './MeasurementTable';
import POrderTable from '../POrder/POrderTable';
import Link from '@mui/material/Link';
import moment from 'moment';
import {postAPI, getAPI, deleteAPI, useTabs, checkUserRoles, activityLog} from '../../utils';

const AttributesStyled = styled(Attributes)(
    ({ theme }) => ({
        ".MuiAttributes-root": {
            [theme.breakpoints.down('md')]: {
                padding: "24px 10px"
            }
        },
        ".MuiAttributes-title": {
            fontSize: "14px",
            fontWeight: 800,
        },
    })
)
  
const ProjectShow = ({
        docId, 
        defaultDoc={},
        onReload=()=>{},
        ...other
    }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [tab, setTab] = useState(0);
    const [doc, setDoc] = useState({});
    const [reload, setReload] = useState(false);
    const [adj_presu, setAdjPresu] = useState(false);
    // const [adj_medicion, setAdjMedicion] = useState(false);
    const [adj_foto, setAdjFoto] = useState(false);

    const currentUser = useSelector(state => state.currentUser);
    const editable = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'obra-edit']);
    const editableTask = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'obra-edit', 'servicio-edit', 'obra-tareas']);
    const editableMeasurement = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'crm-medicion']);
    const downloadable = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'crm-presupuesto-ver']);
    const disabled = ['TCP','T','CO','INCO'].indexOf(doc['status']) > -1;
    
    const {appendTab} = useTabs();

    useEffect( () => {
        setDoc(defaultDoc)
    }, [defaultDoc])

    // OBS no se usa a menos que entre por docId definido
    useEffect( () => {
        debugger
        if (!docId || docId === 'new') return

        setReload(false)
        // reads data to Edit instead of using datagrid row
        if ( docId === 'new' ) return 
        getAPI('/project/' + docId, {withOF:1})
        .then(data => {
            if (data) setDoc(data)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
          });   
      }, [docId, reload, enqueueSnackbar])
    
    const handleClick = tipo => item => (ev) => {
        debugger
        ev.preventDefault();
        if (item.id) appendTab(tipo)(item);
    }

    const handleAdj = (tipo) => (ev) => {
        if (tipo === 'adj_presu') setAdjPresu( !adj_presu);
        // if (tipo === 'adj_medicion') setAdjMedicion( !adj_medicion);
        if (tipo === 'adj_foto') setAdjFoto( !adj_foto);
    }
    const handleUpload = tipoAdjunto => uploadedFile => {
        postAPI('/project/addToSet/' + doc['_id'], {[tipoAdjunto]: uploadedFile})
        .then(async data => {
          debugger
          let msg = `Se agregó el archivo "${uploadedFile.filename}" (${tipoAdjunto}). `
          if (tipoAdjunto === 'archivo_medicion' && doc.status === 'SP') {
            await postAPI('/project/'+doc['_id'], {status: 'F', fecha_medicion: new Date()})
            msg += "Se cambio de estado a F y se agrego fecha de medición."
          }
          activityLog({label: doc.nombre, app:'obras', docId: doc._id, msg: msg, currentUser})

          enqueueSnackbar(msg, {variant: 'info'})
          setReload(true);
          if (onReload) onReload()
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        });  
    }
    const handleUploadDelete = tipoAdjunto => file => ev => {
        const nuevo = doc[tipoAdjunto].filter(it => it.filename !== file.filename);
        return postAPI('/project/' + doc['_id'], {[tipoAdjunto]: nuevo})
        .then(async data => {
            debugger
            let msg = `Se borró el archivo "${file.filename}" (${tipoAdjunto}). `
            if (tipoAdjunto === 'archivo_medicion' && nuevo.length === 0 && doc.status === 'F') {
                await postAPI('/project/'+doc['_id'], {status: 'SP', fecha_medicion: null})
                msg += "Se cambio de estado a SP y se borro fecha de medición."
            }
            deleteAPI('/attachments/' + file._id).then( data => {
                activityLog({label: doc.nombre, app:'obras', docId: doc._id, msg: msg, currentUser})
                enqueueSnackbar(msg, {variant: 'info'})
                setReload(true);
                if (onReload) onReload();    
            })
            .catch(err => {
                enqueueSnackbar(err.message, {variant: 'error'})
            });
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        });
    }
    const handleNuevo = tipo => (ev) => {
        debugger
        if (tipo) {
            appendTab(tipo)({_id: 'new', tipo: tipo == 'WORK-F' ? 'fabrica' : 'servicio', obra: doc})
        }
    }
  
    const getDireccion = () => {
        let out = "";
        if (doc) {
            if (doc.barrio) {
                out = doc.barrio + " " + doc.lote + ", "
            }
            out += (doc.direccion || "SIN")
            out += "-" + (doc.partido  || "SIN")
            out += "-" + (doc.localidad  || "SIN")
            out += "-" + (doc.provincia  || "SIN")
            out += "-" + (doc.pais  || "SIN")    
        }
        return out
    }

    // const cliente = doc.cliente ? doc.cliente._id : {}; 
    const getContact = (fld) => {
        if (!doc || !doc[fld]) return "SIN";
        const fullname = doc[fld].fullname 
        return <Link href="#" onClick={handleClick('CONTACT')(doc[fld])}>{fullname}</Link>            
        // return <a href='#' onClick={handleClick('CONTACT')(it)}>{fullname}</a>
    }
    const getVendedor = () => {
        if (doc.ownerId) {
            return doc.ownerId.initials
        }
    }
    const getOppos = (opos) => {
        return opos && opos.map(it => <span><a href='#' onClick={handleClick('OPPO')(it)}>{it.presupuesto}</a>&nbsp;&nbsp;</span>)
    }

    const handleChangeTab = (ev, newValue) => {
        setTab(newValue)
    }

    const adjuntoTitle = doc.status === 'SP' ? <Badge badgeContent={"SP"} color={'yellow'}>Adjuntos</Badge> : "Adjuntos"

    const panelAdjuntos = [
        {title: 'ARCHIVOS', sizes: {xs:12, sm:2}},
        {   
            label: (
                <AdjuntoToggle 
                    editable={editable} 
                    edit={adj_presu} 
                    onClick={handleAdj('adj_presu')}
                >
                    Archivo Presupuesto
                </AdjuntoToggle>
            ), 
            text: <FileList 
                    folder="/presupuesto"
                    docs={doc.archivo_presupuesto} 
                    editable={adj_presu}
                    downloadable={downloadable}
                    onUploaded={handleUpload('archivo_presupuesto')}
                    onDelete={handleUploadDelete('archivo_presupuesto')}
                />, 
            sizes: {sm:4}
        },
        // {                                
        //     label: (
        //         <AdjuntoToggle editable={editable} edit={adj_medicion} onClick={handleAdj('adj_medicion')}>
        //             Archivo Medición
        //         </AdjuntoToggle>
        //     ), 
        //     text: <FileList 
        //             folder="/medicion"
        //             docs={doc.archivo_medicion} 
        //             editable={adj_medicion}
        //             onUploaded={handleUpload('archivo_medicion')}
        //             onDelete={handleUploadDelete('archivo_medicion')}
        //         />, 
        //     sizes: {sm:4}
        // },
        // {},
        // {},
        {                                
            label: (
                <AdjuntoToggle editable={editable} edit={adj_foto} onClick={handleAdj('adj_foto')}>
                    Archivo Foto
                </AdjuntoToggle>
            ), 
            text: <FileList 
                    folder="/foto"
                    docs={doc.archivo_foto} 
                    editable={adj_foto}
                    onUploaded={handleUpload('archivo_foto')}
                    onDelete={handleUploadDelete('archivo_foto')}                                  
                />, 
            sizes: {sm:4}
        },
        {},
        {},
        {},
    ]

    return (
        <Box {...other}>
            <Tabs
                sx={{'@media print': {display: 'none'}}}
                centered
                value={tab} 
                onChange={handleChangeTab} 
            >
                <Tab id={"obra-0"}  label={"Detalle"} />
                <Tab id={"obra-1"}  label={"Planillas"} />
                <Tab id={"obra-2"}  label={"Planning"} />
                <Tab id={"obra-3"}  label={"Fabricación"} />
                <Tab id={"obra-4"}  label={"Servicios"} />
                <Tab id={"obra-5"}  label={"Compras"} />
                <Tab id={"obra-6"}  label={"Tareas"} />
            </Tabs>

            
            <TabPanel index={0} value={tab} sx={{height: 'calc(100% - 220px)'}}>
                <AttributesStyled 
                    sizes={{xs:6, sm:2}} 
                    list={
                        [
                            {title: 'DATOS', sizes: {xs:12, sm:2}},
                            {label: 'Carpeta+Nombre', text: "[" + doc.carpeta + "] " + doc.nombre},
                            {label: 'Status',       text: doc.status},
                            {label: 'Vendedor',     text: getVendedor()},
                            {label: 'Distribuidor', text: doc.distributorId && doc.distributorId.codigo},
                            {label: 'Presupuesto',  text: doc.presupuesto},
                            {},
                            {label: 'Direccion',    text: getDireccion(), sizes: {xs:12, sm:6}},
                            {label: 'Oportunidades', text: getOppos(doc.oppos) || 'SIN', sizes: {xs: 4}},

                            {title: 'CONTACTOS', sizes: {xs:12, sm:2}},
                            {label: 'Cliente',           text: getContact('cliente')},
                            {label: 'Arquitecto',        text: getContact('arquitecto')},
                            {label: 'Emp. Constructora', text: getContact('emp_constructora')},
                            {label: 'Contacto Obra',     text: getContact('contacto_obra')},
                            {},

                            {title: 'CARACTERISTICAS', sizes: {xs:12, sm:2} },
                            {label: 'Tipo Obra',    text: doc.tipo_obra,    },
                            {label: 'Cantidad',     text: doc.ventanas,     },
                            {label: 'ML',           text: doc.ml,           },
                            {label: 'M2',           text: doc.m2,           },
                            {label: 'Ups',           text: doc.ups ? doc.ups.toString() : ""},
                            {},
                            {label: 'Vidrios',      text: doc.vidrios || "NO", },
                            {label: 'Material',     text: doc.material,     },
                            {label: 'Marca',        text: doc.marca,        },
                            {label: 'Terminacion',  text: doc.terminacion,  },
                            {label: 'Color',        text: doc.color,        },
                            {},
                            {label: "Premarcos",    text: doc.premarcos || "NO",    sizes: {xs:2}},
                            {label: "Contramarcos", text: doc.contramarcos || "NO", sizes: {xs:2}},
                            {label: "Mosquiteros",  text: doc.mosquiteros || "NO",  sizes: {xs:2}},
                            {label: "Cortinas",     text: doc.cortinas || "NO",     sizes: {xs:2}},
                            {},
                            {},
                            {label: 'Observación',  text: doc.observacion || 'SIN', sizes: {xs: 8}},
                            {},
                            {title: 'FECHAS', sizes: {xs:12, sm:2}},
                            {label: 'Fecha Ingreso',   text: doc.fecha_ingreso ? moment(doc.fecha_ingreso).utc().format('YYYY-MM-DD') : "SIN"},
                            {label: 'Fecha Medicion',   text: doc.fecha_medicion ? moment(doc.fecha_medicion).utc().format('YYYY-MM-DD') : "SIN"},
                            {label: 'Fecha Fabricacion',   text: doc.fecha_fabricacion ? moment(doc.fecha_fabricacion).utc().format('YYYY-MM-DD') : "SIN"},
                            {label: 'Fecha Colocacion',    text: doc.fecha_colocacion ? moment(doc.fecha_colocacion).utc().format('YYYY-MM-DD') : "SIN"},
                            {label: 'Fecha Terminacon',   text: doc.fecha_terminacion ? moment(doc.fecha_terminacion).utc().format('YYYY-MM-DD') : "SIN"},
                            {},
                            {label: 'Fecha Cobrado',   text: doc.fecha_cobrado ? moment(doc.fecha_cobrado).utc().format('YYYY-MM-DD') : "SIN"},
                            {label: 'Fecha C90',   text: doc.fecha_c90 ? moment(doc.fecha_c90).utc().format('YYYY-MM-DD') : "SIN"},
                            {},
                            {},
                            {},

                            ...panelAdjuntos,

                        ]
                    }
                />

            </TabPanel>


            <TabPanel index={1} value={tab} sx={{height: 'calc(100% - 220px)'}}> 
                <AttributesStyled 
                    sizes={{xs:12, sm:2}} 
                    list={
                        [
                            {
                                title: <Box>Planillas</Box>, 
                            },
                            {   
                                label: '', 
                                text: <MeasurementTable 
                                            disabled={disabled}
                                            editable={editableMeasurement} 
                                            obraId={doc._id} 
                                            docs={doc.measurements} 
                                            refe={{obra: doc}}
                                            onReload={onReload}
                                        />, 
                                sizes:{xs: 12}
                            },
                        ]                        
                    }
                />
            </TabPanel>


            <TabPanel index={2} value={tab} sx={{height: 'calc(100% - 220px)'}}>
                <StockPlanningShowEdit project={doc} disabled={disabled} />
            </TabPanel>


            <TabPanel index={3} value={tab} sx={{height: 'calc(100% - 220px)'}}> 
                <AttributesStyled 
                    sizes={{xs:12, sm:2}} 
                    list={
                        [
                            {
                                title: <Box>FABRICACION <Button size='small' disabled={disabled} variant='outlined' onClick={handleNuevo('WORK-F')}>NUEVO</Button></Box>, 
                            },
                            {label: '', text: <WorkOrderTable tipo='fabrica' obraId={doc._id} onClick={handleClick}/>, sizes:{xs: 12}},
                        ]                        
                    }
                />
            </TabPanel>


            <TabPanel index={4} value={tab} sx={{height: 'calc(100% - 220px)'}}> 
                <AttributesStyled 
                    sizes={{xs:12, sm:2}} 
                    list={
                        [
                            {
                                title: <Box>SERVICIOS <Button size='small' disabled={disabled} variant='outlined' onClick={handleNuevo('WORK-S')}>NUEVO</Button></Box>, 
                                sizes: {xs:12, sm:2}
                            },
                            {label: '', text: <WorkOrderTable tipo='servicio' obraId={doc._id} onClick={handleClick}/>, sizes:{xs: 12}},
                        ]                        
                    }
                />
            </TabPanel>

            <TabPanel index={5} value={tab} sx={{height: 'calc(100% - 220px)'}}>
                <AttributesStyled 
                    sizes={{xs:12}} 
                    list={
                        [
                            {title: 'COMPRAS', sizes: {xs:12}},
                            {label: '', text: <POrderTable obraId={doc._id} onClick={handleClick('NP')}/>, sizes:{xs: 12}},
                        ]
                    }
                />
            </TabPanel>


            <TabPanel index={6} value={tab} sx={{height: 'calc(100% - 220px)', overflow: 'hidden'}}>

                <AttributesStyled 
                    sx={{height: '100%', overflow: 'hidden'}}
                    sizes={{xs:12}} 
                    list={
                        [
                            {title: 'TAREAS', sizes: {xs:12}},
                            {label: '', text: <TasksTable 
                                                sx={{height: 'calc(100% - 100px)'}} 
                                                reference={{label: doc.nombre, app:'obras', field:'obra', id:doc._id}}
                                                editable={editableTask}
                                            />, 
                                sizes:{ xs: 12}
                            },
                        ]
                    }
                />
            </TabPanel>

        </Box>
    );
};

export default ProjectShow;
