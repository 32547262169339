import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { HeaderBar, MessageBox } from '../../components';
import POrderEdit from './POrderEdit';
import {ROrderDetail} from './POrderDetail';
import POrderInvoice from './POrderInvoice';
import POrderBottomMenu from './POrderBottomMenu';
import { getAPI, deleteAPI, checkUserRoles, useTabs, activityLog, postAPI } from "../../utils";

import moment from 'moment'


export default function EditForm({docId, onReload}) {
    const { enqueueSnackbar } = useSnackbar();
    const [edit, setEdit] = useState(docId === 'new');
    const [reload, setReload]= useState(false);
    const [dialog, setDialog]= useState(false);
    const [ doc, setDoc ] = useState({
      _id: docId,
      rec: '',
      status: "PARA PEDIR", 
      tipo: 'MATERIAL',
      fecha_aprobado: '',
      fecha_pedido: '',
      fecha_confirmado: '',
      fecha_entrega: '',
      fecha_recibido: ''
    });
    debugger
    const {appendTab, updateCurrentTab, removeCurrentTab} = useTabs();
    const currentUser = useSelector(state => state.currentUser);
    const disabled = !checkUserRoles(currentUser, ['local-admin', 'compras-edit'])
    const title = !doc || docId === 'new' ? "Nueva REC" : (edit ? "Edición de REC " + doc.tipo : "Recepción " + doc.tipo);

    useEffect( () => {
      debugger
      setReload(false)
      // reads data to Edit instead of using datagrid row
      if ( docId === 'new' ) return 
      getAPI('/stock/porders/rec/' + docId)
      .then(data => {
          if (data) setDoc(data)
      })
      .catch(err => {
          debugger
          console.log(err.message)
          enqueueSnackbar(err.message, {variant: "error"})
      });   
    }, [docId, edit, reload, enqueueSnackbar])

    const handleDelete = (valor) => {
      setDialog(false)
      if (valor) {
        deleteAPI('/stock/porders/rec/' + docId)
        .then(({res, result}) => {
            const msg = `Se eliminó la recepcion REC-${doc.pedido_orig.np}`
    
            activityLog({label: "NP-" + doc.pedido_orig.np, app:'compras', docId: doc._id, msg, currentUser})    
            enqueueSnackbar(msg, {variant: "info"})

            if (onReload) onReload()
            removeCurrentTab()
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });   
      }
    }

    const handleChangeInvoice = (doc) => {
      debugger
      postAPI('/stock/porders/rec/' + docId + '/invoice', doc)
      .then(({res, result}) => {
        debugger
        enqueueSnackbar("Se modifico la factura", {variant: "info"})
        setReload(true)
      })
      .catch(err => {
          debugger
          console.log(err.message)
          enqueueSnackbar(err.message, {variant: "error"})
      });   
    }

    const handleEdit = () => {
      setEdit(!edit)
    }

    const handleCancel = () => {
      setEdit(false)
    }

    const handleClose = (ev) => {
      setEdit(false)
      if (onReload) onReload()
    }

    const handleDeleteDialog = () => {
      setDialog(true)
    }

    const provee = doc && doc.provee || {};
    return (
      <Paper sx={{p:2, width: '100%'}}> 
        <HeaderBar 
          title={[
            `${title} #${doc.rec} (REMITO #${doc.remito}) PEDIDO NP-${doc.pedido_orig && doc.pedido_orig.np}`, 
          ]}
          actions={
            <Stack direction='row' spacing={2}>
              {/* <Button autoFocus variant='outlined' size='small' color="inherit" disabled={disabled} onClick={handleEdit}>{!edit ? "Editar" : "Cancelar"}</Button> */}
              {doc._id !== 'new' && <Button autoFocus variant='outlined' size='small' color="inherit" disabled={disabled} onClick={handleDeleteDialog}>Borrar</Button>}
            </Stack>
          }
          info={[
            <Box sx={{display: 'inline-block'}}>
              de [{provee.codigo_proveedor}] {provee.fullname} recibido [{moment(doc.fecha_recibido).utc().format('YYYY-MM-DD')}]
              &nbsp;
              <POrderInvoice doc={doc.factura || {fecha: moment(), moneda: 'ARG'}} onSubmit={handleChangeInvoice}/>
            </Box>,
          ]}
        />
        <Box sx={{m:2}}/>

        <Box sx={{height: '100%'}}>
          
          {false && edit && <POrderEdit doc={doc} onClose={handleClose} onCancel={handleCancel} /> }

          {!edit && doc && (
            <Box sx={{height:'100%'}}>
              <ROrderDetail sx={{height:"50%"}} doc={doc}/>
              <Stack direction='row' spacing={2}>
              <Typography>Lineas={doc.detailRec && doc.detailRec.length}</Typography>
              </Stack>
            </Box>
          )}

        </Box>

        <MessageBox 
            title="Esta seguro que quiere eliminar esta Recepción?"
            open={dialog} 
            onClose={handleDelete}
        />

      </Paper>
  )
}
