import React, {useState, useEffect} from 'react';
import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { BarrioAutocompleteInput, LocalidadAutocompleteInput, ProvinciaAutocompleteInput, PartidoAutocompleteInput, PaisAutocompleteInput} from '../Settings/Places/PlacesAutocomplete';
import { PosventaAutocompleteObjectInput } from '../Settings/Users/UserAutocomplete';
import { ContactAutocompleteObjectInput } from '../Contact/ContactAutocomplete';
import { ProjectAutocompleteObjectInput } from '../Project/ProjectAutocomplete';
import { PageBase, Form, SelectInput } from '../../components';
import { getAPI, postAPI, checkUserRoles, activityLog } from '../../utils';

import {options_postventa_status, options_sino} from '../../appConfig';
 
const defaultDoc = {
    status: 'INGRESADA',
}

const PostSaleEdit = ({
        docId, 
        edit=true, 
        debug=false,
        onAfterSubmit=(data) => {},
        onCancel=() => {},
        ...other
    }) => {

    let params = useParams();
    const currentUser = useSelector(state => state.currentUser);
    const [doc, setDoc] = useState({...defaultDoc, ownerId: currentUser});
    const [docOrig, setDocOrig] = useState();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    let id = docId || params.id;
 
    const canOpts = useSelector( state => state.distributors)
    const optionsDistrib = canOpts ? canOpts.map(it => ({label:it.nombre, value:it._id}) ) : [];

    const optionsAll = useSelector(state => state.options) || {};
    const options_postventa_tipo = optionsAll['postsaleType'] || [];

    // eslint-disable-next-line
    const hidden = !checkUserRoles(currentUser, ['local-admin']);
    const onlyAdmin = checkUserRoles(currentUser, ['local-admin']);

    // useEffect( () => {
    //     if (!id || id === 'new') {
    //         setDoc( d => ({...defaultDoc, ownerId: currentUser}))
    //     }
    // }, [id, currentUser])

    useEffect( () => {
        if (!id || id === "new") return;

        setLoading(true);
        getAPI('/postsale/postsales/' + id)
        .then(data => {
            //debugger
            setLoading(false);
            setDoc(data);
            if (!edit) {
                setDocOrig(data);
            }
        });
    }, [id, edit]);

    const onSubmit = (data, diffMgs) => {
        debugger
        if (id === 'new') {
            id = "";
        }
        if (!data["contacto"]) delete data['contacto'];
        if (!data["obra"]) delete data['obra'];

        postAPI('/postsale/postsales/' + id, data)
        .then(data => {
            debugger
            const doc = data.doc; 
            if (id === '') {
                const msg = "Se guardó nuevo PostVenta"
                activityLog({label: "PV-" + doc.pv, app:'posventa', docId: doc._id, msg, currentUser})
                enqueueSnackbar(msg, {variant: 'info'})
            } else {
                activityLog({label: "PV-" + doc.pv, app:'posventa', docId, diffMgs, currentUser})
                enqueueSnackbar("Se guardo el cambio correctamente", {variant: 'info'})
            }
            onAfterSubmit(doc)
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        });
    }
  
    // const watchChanges = (value, name, type, getValues, setValue) => {
    //     debugger
    //     if ( type==='change') {
    //         if (name === 'marca') {
    //             setValue('terminacion', '')
    //             setValue('color', '')
    //         }
    //         if ( name === 'terminacion') {
    //             setValue('color', '')
    //         }
    //     } 
    // }

    const diffFnContacto = (orig) => {
        if (!orig || Object.keys(orig).length === 0) return 'SIN';
        if (orig && orig['fullname']) {
            return orig['fullname'];
        }
    }

    const sectionTrabajo = [
        {
            name: 'trabajo',
            label: 'Trabajo',
            type: 'divider',
            sizes: {xs: 12}
        },
        {
            label: 'Descripcion',
            name: 'descripcion',
            multiline: true,
            minRows: 3,
            rules: {
                required: "Debe ingresar una descripcion completa",
            },
            sizes: {xs:12}
        },
        {
            label: 'Tipo',
            name: 'tipo',
            options: options_postventa_tipo,
            rules: {
                required: "Debe seleccionar valor",
            },
            sizes: {xs:6, sm:2},
        },
        {
            label: 'En Garantia',
            name: 'en_garantia',
            options: options_sino,
            rules: {
                required: "Debe ingresar ",
            },
            sizes: {xs:6, sm:2},
        },
        {
            label: 'Referencia',
            name: 'referencia',
            rules: {
                // required: "Debe ingresar ",
            },
            sizes: {xs:6, sm:2},
        },
        {
            label: "Contacto",
            name: 'contacto',
            Component: ({...other}) => ContactAutocompleteObjectInput({...other, withAdd: !!edit}),
            diffFn: diffFnContacto,
            rules: {
                //   required: "Debe ingresar valor",
            },
            sizes: {xs:6, sm:3},
        },
        {
            label: "Obra",
            name: 'obra',
            Component: ProjectAutocompleteObjectInput,
            diffFn: (orig) => "[" + orig['carpeta'] + "] " + orig['nombre'],
            sizes: {xs:6, sm:3}
        },
        {
            label: 'Pv',
            name: 'pv',
            rules: {
                //required: "Debe ingresar valor",
            },
            sizes: {xs:6, sm:2},
            disabled: !onlyAdmin,
        },
        {
            label: 'Nombre PV',
            name: 'nombre',
            rules: {
                //required: "Debe ingresar valor",
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:6, sm:2},
        },
        {
            label: 'Con Presupuesto',
            name: 'con_presupuesto',
            options: options_sino,
            rules: {
                // required: "Debe ingresar ",
            },
            sizes: {xs:6, sm:2},
        },
        {
            label: 'Distribuidor',
            name: 'distributorId',
            Component: ({...other}) => <SelectInput {...other} localField="_id" />,
            diffFn: (orig) => {
                if (orig && orig['_id']) return orig['_id']
                return orig
            },
            options: optionsDistrib,
            rules: {
                // required: "Debe ingresar ",
            },
            sizes: {xs:6, sm:2},
        },
        {
            label: 'Valor',
            name: 'valor',
            rules: {
                //required: "Debe ingresar valor",
            },
            sizes: {xs:6, sm:2},
        },
        {
            label: 'Presupuesto',
            name: 'presupuesto',
            rules: {
                //required: "Debe ingresar valor",
            },
            sizes: {xs:6, sm:2},
        },
    ]

    const sectionDireccion = [
        {
            name: 'direccion',
            label: 'Direccion',
            type: 'divider',
            sizes: {xs: 12}
        },
        {
            label: 'Barrio',
            name: 'barrio',
            Component: BarrioAutocompleteInput,
            rules: {
                //required: "Debe ingresar valor",
            },
            sizes: {xs:8, sm:4}
        },
        {
            label: 'Lote',
            name: 'lote',
            rules: {
                //required: "Debe ingresar valor",
            },
            sizes: {xs:4, sm:2}
        },
        {
            label: 'Direccion',
            name: 'direccion',
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                },
            },
            sizes: {xs:12, sm:6}
        },
        {
            label: 'Localidad',
            name: 'localidad',
            Component: LocalidadAutocompleteInput,
            rules: {
                // validate: {
                //     exists: (v) => !!v || 'Es obligatorio',
                //     isEmail: v => /.*@.*/ || "Debe ingresar formato kkkk@sss.ddd",
                // },
            },
            sizes: {xs:6, sm:3},
        },
        {
            label: 'Partido',
            name: 'partido',
            Component: PartidoAutocompleteInput,
            rules: {
                required: "Debe ingresar valor",
                // validate: {
                //     exists: (v) => !!v || 'Es obligatorio',
                //     isEmail: v => /.*@.*/ || "Debe ingresar formato kkkk@sss.ddd",
                // },
            },
            sizes: {xs:6, sm:3},
        },
        {
            label: 'Provincia',
            name: 'provincia',
            Component: ProvinciaAutocompleteInput,
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:6, sm:3},
        },
        {
            label: 'Pais',
            name: 'pais',
            Component: PaisAutocompleteInput,
            rules: {
            },
            sizes: {xs:6, sm:3},
        },
        {
          label: 'Zona',
          name: 'zona',
          rules: {
              // required: "Debe ingresar ",
          },
          sizes: {xs:6, sm:2},
        },
        {
          label: 'Email',
          name: 'email',
          rules: {
              required: "Debe ingresar valor",
          },
          sizes: {xs:6, sm:2},
        },
        {
          label: 'Telefono',
          name: 'telefono',
          rules: {
              required: "Debe ingresar valor",
          },
          sizes: {xs:6, sm:2},
        },

    ]
    
    const sectionAdmin = [
        {
            label: "Admin",
            name: "admin",
            type: 'divider',
            sizes: {xs:12}
        },
        {
          label: 'Status',
          name: 'status',
          options: options_postventa_status,
          disabled: !onlyAdmin,
          sizes: {xs:6, sm:2},
        },
        {
            label: 'Responsable',
            name: 'ownerId',
            Component: PosventaAutocompleteObjectInput,
            diffFn: (orig) => {return orig ? orig['username'] : 'SIN'},
            rules: {
                required: "Debe seleccionar valor",
            },
            sizes: {xs:6, sm:2},
        },
        {
          label: 'Fecha Recepcion',
          name: 'fecha_recepcion',
          dateProps: {format:"YYYY-MM-DD"},
          disabled: !onlyAdmin,
          sizes: {xs:6, sm:2},
        },
        {
          label: 'Fecha Enviada',
          name: 'fecha_enviada',
          dateProps: {format:"YYYY-MM-DD"},
          // disabled: !onlyAdmin,
          rules: {
          },
          sizes: {xs:6, sm:2},
        },
        {
            label: 'Fecha Aceptada',
            name: 'fecha_aceptada',
            dateProps: {format:"YYYY-MM-DD"},
            rules: {
                // required: "Debe ingresar valor"
            },
            sizes: {xs:6, sm:2},
        },
        {
            label: 'Fecha Aprobada',
            name: 'fecha_aprobada',
            dateProps: {format:"YYYY-MM-DD"},
            // disabled: !onlyAdmin,
            rules: {
            },
            sizes: {xs:6, sm:2},
        },
        {
            label: 'Fecha Cerrada',
            name: 'fecha_cerrada',
            dateProps: {format:"YYYY-MM-DD"},
            // disabled: !onlyAdmin,
            rules: {
            },
            sizes: {xs:6, sm:2},
        },
    ]

    const fieldsArr = [...sectionTrabajo, ...sectionDireccion];
    if (onlyAdmin) {
        fieldsArr.push(...sectionAdmin)
    }

    return (
        <Box {...other}>
            <Form
                loading={loading}
                disabled={!edit}
                fields={fieldsArr}
                defaultValues={doc}
                // watchChanges={watchChanges}
                onSubmit={onSubmit}
                actionsComp={ (onSubmit, isValid) => (
                    <Box sx={{textAlign: 'center', margin:'10px'}}>
                        <Button 
                            sx={{minWidth: '100px', marginRight: '10px'}}
                            color='secondary'
                            variant='outlined' 
                            onClick={onCancel}>Cancelar</Button>
                        <Button 
                            sx={{minWidth: '100px'}}
                            variant="outlined" 
                            //disabled={!isValid} 
                            onClick={onSubmit}>Guardar</Button>
                    </Box>
                    )                    
                }
                debug={debug}
            />
        </Box>
    );
};

export default PostSaleEdit;


export const PostSalePage = ({...other}) => (
    <PageBase title={"PostVenta"}>
        <PostSaleEdit {...other} />
    </PageBase>
)