import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 

import { useSearchParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import {PageBase, PageTitle, SearchBox, ExportButton} from '../../components'; 
import {getAPI, useTabs, checkUserRoles, debounce} from '../../utils' ;
import WorkOrderList from './WorkOrderList';
import RecursosBox from './RecursosBox';
import ActivityOpenClose  from '../Activities/ActivityOpenClose';
import {options_orders_fabrica_status, options_orders_servicios_status, options_o, EXCEL_LIMIT} from '../../appConfig';

import moment from 'moment'

const IndexPage = forwardRef( ({tipo="WORK-F", isTabSelected}, ref) => {
    let [searchParams] = useSearchParams();
    const { enqueueSnackbar }       = useSnackbar();
    const [filter, setFilter]       = useState(searchParams.get("filter")  || "");
    const [status, setStatus]       = useState(searchParams.get("status")  || "-1");
    const [categoria, setCategoria] = useState(searchParams.get("categoria")  || "-1");
    const [obras,  setObra]         = useState(searchParams.get("obras")   || "");
    const [recurso, setRecurso]     = useState(searchParams.get("recurso") || "-1");
    const [recursos, setRecursos]   = useState(searchParams.get("recursos") || []);
    const [distribuidor, setDistribuidores] = useState("")
    const [reload, setReload]   = useState(false);
    const {appendTab} = useTabs({
        onReload: (reload) => {
            debugger
            setReload(reload)
        }
    });

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;
    
    const currentUser = useSelector(state => state.currentUser);
    const roles = [
        'local-admin', 'gerencia',
        tipo === 'WORK-F' ? 'fabrica-edit' : 'servicio-edit'
    ]
    const esLocalAdmin = checkUserRoles(currentUser, roles)

    const sort = 'updated_at:-1';

    const optionsAll = useSelector(state => state.options) || {};
    const options_categoria = [{label:'TODOS', value: "-1"}].concat(optionsAll[tipo === 'WORK-F' ?'tipoOF':'tipoOS'] || []);

    const options_status0 = tipo === "WORK-F" ? options_orders_fabrica_status : options_orders_servicios_status;
    const options_status = [{label: "TODOS", value: "-1"}].
       concat(options_status0.map(it => {return {label: it, value: it}}));
    const canOpts = useSelector( state => state.distributors)
    const optionsDistrib = canOpts ? ([{label: 'TODOS', value:'-1'}].concat(canOpts.map(it => ({label:it.nombre, value:it._id}) )  )) : [];
   
    // const canOpts = useSelector( state => state.distributors)
    // const optionsDistrib = canOpts ? ([{label: 'TODOS', value:'-1'}].concat(canOpts.map(it => ({label:it.nombre, value:it.codigo}) )  )) : [];
    // const optionsStatus = options_opos_status.map( it => ({label: it, value: it}))

    useEffect( () => {
        debugger
        const aa = canOpts.filter( it => !!it.default );
        if (aa.length) {
            setDistribuidores(aa[0]._id)
        } else {
            setDistribuidores(canOpts[0]._id)
        }
    }, [canOpts])

    const fn = useCallback( 
        debounce(
            (page, filter, obras, status, categoria, recurso, recursos, distribuidor, tipo, enqueueSnackbar) => {
                setReload(false)
                setLoading(true)
                const rec = tipo === 'WORK-F' ? [recurso] : recursos;
                getAPI('/work/workorders/', {filter, status, categoria, obras, tipo, distribuidor, recurso: rec, sort, skip: page*limit, limit})
                .then(data => {
                    debugger
                    setLoading(false);
                    data.list.forEach( it => it.id = it._id);
                    setNum(data.num)
                    setRows(data.list)
                  })
                .catch(err => {
                    console.error(err)
                    enqueueSnackbar(err.message, {variant: 'error'})
                });
            }, 500),
        []
    )        
    useEffect( () => {
        fn(page, filter, obras, status, categoria, recurso, recursos, distribuidor, tipo, enqueueSnackbar)
    }, [fn, page, filter, obras, status, categoria, recurso, recursos, distribuidor, tipo, reload, isTabSelected, enqueueSnackbar])

    const handleSearch = fld => (ev) => {
        debugger
        if (fld === 'filter') setFilter(ev.target.value)
        if (fld === 'status') setStatus(ev.target.value)
        if (fld === 'categoria') setCategoria(ev.target.value)
        if (fld === 'obras') setObra(ev.target.value)
        if (fld === 'distribuidor') setDistribuidores(ev.target.value)
        if (fld === 'recurso') setRecurso(ev.target.value)
        if (fld === 'recursos') setRecursos(ev.target.value)
    }

    const handleClick= tipo => item => (ev) => {
        debugger
        ev.preventDefault();
        if (item.id) appendTab( tipo )(item)
    }

    const configReport = useCallback( async () => {
        const reportConfig = {
            title: `Ordenes de ${tipo === 'WORK-F' ? 'Fabrica' : 'Servicio'}`,
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: [
                    {title: tipo === 'WORK-F' ? 'OF' : "OS", field: '_', transform: (v, doc) => tipo==='WORK-F' ? 'OF-'+ doc.of : 'OS-' + doc.of},
                    {title: 'Status', field: 'status'},
                    {title: 'Obra', field: '_', transform: (v, doc) => doc.obra && `[${doc.obra.carpeta}] ${doc.obra.nombre}`  },
                    {title: 'Posventa', field: '_', transform: (v, doc) => doc.pv && `[${doc.pv.pv}] ${doc.pv.nombre}`  },
                    {title: 'NP', field: '_', transform: (v, doc) => doc.nps && `${doc.nps.filter(ii => !!ii.np).map(it => (`[${it.np}/${it.status}] ${it.provee && it.provee.fullname}`)).join(', ')}`},
                    {title: 'Fecha Inicio', field: '_', transform: (v, doc) => {
                        const f1 = doc.plan_fecha_inicio, f2 = doc.fecha_inicio; return moment(f2 ? f2 : f1).utc().format('YYYY-MM-DD')}
                    },
                    {title: 'Fecha Fin', field: '_', transform: (v, doc) => {
                        const f1 = doc.plan_fecha_finalizacion, f2 = doc.fecha_finalizacion; return moment(f2 ? f2 : f1).utc().format('YYYY-MM-DD')}
                    },
                    {title: 'Realizado (d)', field: '_', transform: (v, doc) => {
                        if (doc.fecha_finalizacion && doc.fecha_inicio) { 
                            return moment(doc.fecha_finalizacion).diff(doc.fecha_inicio, 'days')
                        }
                        return '-'
                    }},
                    tipo === 'WORK-F' && 
                    {title: 'Ret. En (d)', field: '_', transform: (v, doc) => {
                        if (['FABRICADO','RETIRADO','COLOCADO'].indexOf(doc.status) > -1) {
                            return moment(doc.fecha_retirado).diff(doc.fecha_finalizacion, 'days')
                        }                  
                        return '-'
                    }},
                    {title: 'Recurso', field: '_', transform: (v, doc) => tipo === 'WORK-F' ? doc.recurso : doc.recursos.join(', ')},
                    {title: 'Cantidad', field: 'cantidad'},
                    {title: 'UPS', field: 'ups'},
                    {title: 'Reproceso', field: 'reproceso'},
                    {title: 'Categoria', field: 'categoria'},
                    {title: 'OF REL', field: '_', transform: (v, doc) => doc.of_rel && `${doc.of_rel.tipo === 'fabrica' ? 'OF':'OS'}-${doc.of_rel.of}`},
                    {title: 'Responsable', field: '_', transform: (v, doc) => doc.ownerId && doc.ownerId.username},
                    {title: 'Actualizado', field: 'updated_at', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
            ],
            styles: {}
        }
    
        const rows = await getAPI('/work/workorders/', {filter, obras, status, recurso, recursos, distribuidor, tipo, skip: 0, limit: 3*EXCEL_LIMIT})

        reportConfig['rows'] = rows.list;
    
        return reportConfig;
      }, [filter, obras, status, recurso, recursos, distribuidor, tipo, currentUser]);

    return (
        <PageBase
            paperProps={{width:'100%', height: 'calc(100% - 98px)'}} 

            title={
                <>
                    <PageTitle>Ordenes {tipo === 'WORK-F' ? 'Fabrica':'Servicio'} ({num})</PageTitle>
                    <SearchBox sx1={{flexGrow: 1, marginRight: 2}} label="Filtro" value={filter} onChange={handleSearch('filter')}/>
                    <SearchBox label="Status" select options={options_status} value={status} onChange={handleSearch('status')}/>
                    <Hidden mdDown>
                        <SearchBox sx={{flexGrow: 0}} label="Obra/PV" value={obras} onChange={handleSearch('obras')}/>
                        <SearchBox sx={{minWidth:100, mr:1}} label="Categoria" select options={options_categoria} value={categoria} onChange={handleSearch('categoria')}/>
                        <SearchBox sx={{minWidth:100, mr:1}} label="Distribuidor" select options={optionsDistrib} value={distribuidor} onChange={handleSearch('distribuidor')}/>
                        {tipo === 'WORK-F' && <RecursosBox label="Recurso" tipo={tipo} value={recurso} onChange={handleSearch('recurso')}/>}
                        {tipo === 'WORK-S' && <RecursosBox label="Recursos" multiple tipo={tipo} value={recursos} onChange={handleSearch('recursos')}/>}
                    </Hidden>
                </>
            } 
            actions={
                <Box sx={{display: 'flex'}}>
                    {esLocalAdmin && <Button sx={{m:1, display: 'inline'}} size='small' variant='outlined' onClick={handleClick(tipo)({id: true, _id: 'new'})}>Nuevo</Button>} 
                    <ExportButton configReport={configReport}>EXCEL</ExportButton>
                    <ActivityOpenClose app={tipo === 'WORK-F' ? 'fabrica' : 'servicio'} withLabel/>
                </Box>
            }   
       >           
            <WorkOrderList
                tipo={tipo}
                loading={loading} 
                docs={rows} 
                num={num}
                limit={limit}
                disabled={!esLocalAdmin}

                onClick={handleClick}
                onPageChange={(newPage) => setPage(newPage)}
            />    

        </PageBase>
    )
})

export default IndexPage;