import React, {forwardRef, useState, useEffect} from 'react'; 
import { useSearchParams } from 'react-router-dom';
import { useSelector } from "react-redux";

import Box from '@mui/material/Box';

import {PageBase, PageTitle, SearchBox} from '../../components'; 
import {WorkOrderScheduleTable} from './WorkOrderScheduleTable';
import RecursosBox from './RecursosBox';
import ActivityOpenClose  from '../Activities/ActivityOpenClose';

const IndexPage = forwardRef( ({tipo="WORK-F", tabKey, isTabSelected}, ref) => {
    let [searchParams] = useSearchParams();
    const resources = useSelector(state => state.resources);
    const options_recursos = resources[tipo === 'WORK-F' ? 'fabrica' : 'servicio'].map(it => it.nombre);
    const [recurso, setRecurso] = useState(searchParams.get("recurso") || [tipo === 'WORK-F' ? options_recursos[0] : '-1']);
    const [project, setProject] = useState("");
    const [distribuidor, setDistribuidores] = useState("")
    const [check, setCheck] = useState(false);

    const canOpts = useSelector( state => state.distributors)
    const optionsDistrib = canOpts ? ([{label: 'TODOS', value:'-1'}].concat(canOpts.map(it => ({label:it.nombre, value:it._id}) )  )) : [];

    useEffect( () => {
        debugger
        const aa = canOpts.filter( it => !!it.default );
        if (aa.length) {
            setDistribuidores(aa[0]._id)
        } else {
            setDistribuidores(canOpts[0]._id)
        }
    }, [canOpts])

    const handleRecurso = (ev) => {
        setRecurso(ev.target.value)
    }

    const handleCheck = (ev) => {
        setCheck(ev.target.checked)
    }

    const handleProject = (ev) => {
        setProject(ev.target.value)
    }
    const handleDistribuidor = (ev) => {
        setDistribuidores(ev.target.value)
    }

    return (
        <PageBase
            paperProps={{width:'100%', height: 'calc(100% - 98px)'}} 

            title={
                <div>
                    <PageTitle>Seguimiento {tipo === 'WORK-F' ? 'Fabrica':'Servicio'}</PageTitle> 
                    <SearchBox label='Obra/PV *' value={project} onChange={handleProject}/>
                    {tipo === 'WORK-F' && <RecursosBox label="Recurso" tipo={tipo} value={recurso} onChange={handleRecurso}/>}
                    {tipo === 'WORK-S' && <RecursosBox label="Recursos" multiple tipo={tipo} value={recurso} onChange={handleRecurso}/>}
                    <SearchBox sx={{minWidth:100, mr:1}} label="Distribuidor" select options={optionsDistrib} value={distribuidor} onChange={handleDistribuidor}/>
                    {tipo === 'WORK-F' && <SearchBox label='incluye Fabricadas' value={check} type='checkbox' onClick={handleCheck}/>}
                </div>
            }
            actions={
                <Box sx={{display: 'flex'}}>
                    {/* <Button sx={{m:1, display: 'inline'}} size='small' variant='outlined' onClick={handleClick(tipo)({id: true, _id: 'new'})}>Nuevo</Button>                         */}
                    <ActivityOpenClose app={tipo === 'WORK-F' ? 'fabrica' : 'servicio'} withLabel/>
                </Box>
            }   
       >           
            <WorkOrderScheduleTable
                tipo={tipo === 'WORK-F' ? 'fabrica' : 'servicio'}
                project={project}
                resource={recurso}
                distribuidor={distribuidor}
                withFab={!!check}
                reload={isTabSelected}
            />    

        </PageBase>
    )
})

export default IndexPage;