import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { SelectField } from '../../components';

export const AlmacenField = ({noneteable=false, ...other}) => {
    debugger
    const warehouses = useSelector( state => state.warehouses || [] );
    const options = warehouses.filter(it => noneteable || it.noneteable == 0).map( it => ({label: it.codigo, value: it._id}))
    return (
        <SelectField label="Almacen" options={options} {...other} />
    )
}