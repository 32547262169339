import React, {useState} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinkIcon from '@mui/icons-material/Link';
import {AdjuntoToggle, Attributes} from '../../components';
import OpportunityModal from './OpportunityModal';
import FileList from '../Attachments/FileList';
import {postAPI, useTabs, checkUserRoles, activityLog} from '../../utils';
import moment from 'moment';

import { makeStyles } from '@mui/styles';
import { defaultTheme } from '../../utils';
import { getInputAdornmentUtilityClass } from '@mui/material';

const useStyles = makeStyles(
  (theme) => ({
    root: {
        color: '#333',
        padding: '10px',
        height: "100%"
    },
    ganada: {
        backgroundColor: '#8bc34a'
    },
    perdida: {
        backgroundColor: "#f20b56ed"
    },
    presupuestar: {
        backgroundColor: '#ffeb3b'
    },
    textoOverflow: {        
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        maxWidth: '87%', 
        display: 'inline-block'
    },
    title: {
        fontSize: "14px",
        fontWeight: 800  
    },
}), { defaultTheme } )

const AttributesStyled = styled(Attributes)(({ theme }) => ({
        ".MuiAttributes-title": {
            fontSize: "14px",
            fontWeight: 800,
        },
    })
)

const OpportunityShow = ({
        doc,
        withHeader=true,
        onReload=()=>{},
        ...other
    }) => {

    let classes = useStyles();
    const {appendTab} = useTabs();
    const [edit, setEdit] = useState(false);
    const [adj_presu, setAdjPresu] = useState(false);
    const [adj_arch, setAdjArch] = useState(false);
    const [adj_aprobar, setAdjAprobar] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const currentUser = useSelector(state => state.currentUser);
    const editable = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'crm-edit']);
    const downloadable = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'crm-presupuesto-ver'])
    const approvable   = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'crm-presupuesto-aprobar'])
    const editableParaAprobar = editable && doc.status === 'A PRESUPUESTAR'

    const handleChangeStatus = (ev) => {
        ev.preventDefault()
    }
    const handleClick = tipo => item => (ev) => {
        debugger
        ev.preventDefault();
        if (item.id) appendTab(tipo)(item);
    }
    const handleAdj = (tipo) => (ev) => {
        if (tipo === 'adj_presu') setAdjPresu( !adj_presu);
        if (tipo === 'adj_arch') setAdjArch( !adj_arch);
        if (tipo === 'adj_aprobar') setAdjAprobar( !adj_aprobar);
    }
    const handleUpload = tipoAdjunto => uploadedFile => {
        debugger
        postAPI('/crm/oppos/addToSet/' + doc['_id'], {[tipoAdjunto]: uploadedFile})
        .then(data => {
          debugger
          const msg = `Se agregó el ${tipoAdjunto} (adjunto) "${uploadedFile.filename}"`
          activityLog({label: doc.nombre, app:'comercial', docId: doc._id, msg: msg, currentUser})

          enqueueSnackbar(msg, {variant: 'info'})
          onReload();
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        });  
    }
    const handleUploadDelete = tipoAdjunto => file => ev => {
        debugger
        const nuevo = doc[tipoAdjunto].filter(it => it.filename !== file.filename);
        // OBS importante hacer return del promise!
        return postAPI('/crm/oppos/' + doc['_id'], {[tipoAdjunto]: nuevo})
        .then(data => {
          debugger
          const msg = `Se borró el archivo ${tipoAdjunto} "${file.filename}"`
          activityLog({label: doc.nombre, app:'comercial', docId: doc._id, msg: msg, currentUser})
          enqueueSnackbar(msg , {variant: 'info'})
          onReload();
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        });
    }
    const handleAprobar = () => {
        const cambios = {
            archivo_presupuesto: [...doc.archivo_presupuesto, ...doc.para_aprobar],
            para_aprobar: [],
            status: 'EN TRATATIVA'
        };
        const nombres = doc.para_aprobar.map(it => it.filename)
        postAPI('/crm/oppos/' + doc['_id'], cambios)
        .then(data => {
          debugger
          const msg = `Se aprobaron los archivos "${nombres}" y se pasó a tratativa.`
          activityLog({label: doc.nombre, app:'comercial', docId: doc._id, msg: msg, currentUser})
          enqueueSnackbar(msg , {variant: 'info'})
          onReload();
          setAdjAprobar(false)
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        });
    }

    const contacto = doc.contacto ? doc.contacto : {}; 
    const getName = () => {
        if (!contacto) return "";
        const nombre = contacto.fullname;
        return <Link href="#" onClick={handleClick('CONTACT')(contacto)}>{nombre}</Link>            
    }
    const getDireccion = () => {
        let out = ""
        if (doc.barrio) {
            out = doc.barrio + " " + doc.lote + ", "
        }
        out += doc.direccion 
        out += " " + doc.partido
        out += " " + doc.ciudad
        return out
    }
    const getTelefono = () => {
        if (contacto.celular) {
            return contacto.celular;
        } else return contacto.telefono
    }

    const getParams = (doc) => (
        [
            {title: 'DESCRIPCION', sizes: {xs:12, sm:2}},
            {label: 'Descripción',  text: (doc.descripcion || 'SIN'), sizes: {sm:6}},
            {label: 'Estado',  text: doc.status, sizes: {xs:6, sm:2}},
            {},
            {title: 'CONTACTO', sizes: {xs:12, sm:2}},
            {label: 'Contacto',     text: getName()},
            {label: 'Email',        children: <span style={{display: 'flex'}}><p className={classes.textoOverflow}>{contacto.email}</p>{contacto.email && <a style={{float:'right'}} href={"mailto:"+contacto.email}><LinkIcon fontSize='small'/></a>}</span>},
            {label: 'Telefono/Celular',     text: getTelefono()},
            {label: 'Direccion',    text: getDireccion(), sizes: {sm:4}},

            {title: 'PROPUESTA', sizes: {xs:12, sm:2}},
            {label: 'Vendedor',       text: doc.ownerId ? doc.ownerId.username : ""},
            {label: 'Presupuesto',    text: doc.presupuesto},
            {label: 'Probabilidad',   text: doc.exito + "%"},
            {label: 'Valor',          text: doc.valor + " " + doc.moneda},
            {label: 'Valor Fab.',     text: doc.dto_profesional + " " + doc.moneda},
            {},
            {label: 'Distribuidor',          text: doc.distributorId && doc.distributorId.codigo},
            {label: 'Dto Profesional', text: doc.dto_profesional},
            {label: 'Ingresado Por',  text: doc.ingreso_por},
            {label: 'Motivo Perdida', text: doc.motivo_perdida},
            {},
            {},
            {label: 'Fecha Ingreso',  text: doc.fecha_ingreso  && moment(doc.fecha_ingreso).utc().format('YYYY-MM-DD')},
            {label: 'Fecha Revision', text: doc.fecha_revision && moment(doc.fecha_revision).utc().format('YYYY-MM-DD')},
            {label: 'Fecha Estimada', text: doc.fecha_estimada && moment(doc.fecha_estimada).utc().format('YYYY-MM-DD')},
            {label: 'Fecha Ganada',   text: doc.fecha_ganada ? moment(doc.fecha_ganada).utc().format('YYYY-MM-DD') : "-"},
            {label: 'Fecha Cierre',   text: doc.fecha_cierre ? moment(doc.fecha_cierre).utc().format('YYYY-MM-DD') : "-"},

            {title: 'CARACTERISTICAS', sizes: {xs:12, sm:2}},
            {label: 'Tipo Obra',    text: doc.tipo_obra,    sizes: {xs:4, sm:2}},
            {label: 'Cantidad',     text: doc.cantidad,     sizes: {xs:4, sm:2}},
            {label: 'ML',           text: doc.ml,           sizes: {xs:4, sm:2}},
            {label: 'M2',           text: doc.m2,           sizes: {xs:4, sm:2}},
            {sizes: {xs:2}},
            {sizes: {xs:2}},
            {label: 'Vidrios',      text: doc.vidrios,      sizes: {xs:4, sm:2}},
            {label: 'Material',     text: doc.material,     sizes: {xs:4, sm:2}},
            {label: 'Marca',        text: doc.marca,        sizes: {xs:4, sm:2}},
            {label: 'Terminacion',  text: doc.terminacion,  sizes: {xs:4, sm:2}},
            {label: 'Color',        text: doc.color,        sizes: {xs:4, sm:2}},
            {sizes: {xs:2}},
            {label: "Premarcos",    text: doc.premarcos,    sizes: {xs:4, sm:2}},
            {label: "Contramarcos", text: doc.contramarcos, sizes: {xs:4, sm:2}},
            {label: "Mosquiteros",  text: doc.mosquiteros,  sizes: {xs:4, sm:2}},
            {label: "Cortinas",     text: doc.cortinas,     sizes: {xs:4, sm:2}},
            {sizes: {xs:2}},
            {sizes: {xs:2}},
            {label: 'Observación',  text: doc.observacion || 'SIN', sizes: {xs:12, sm:8}},
            {sizes: {xs:2}},

            {   title: 'PARA APROBAR', 
                children: approvable && doc.para_aprobar && !!doc.para_aprobar.length &&
                    <Box>
                        <Button sx={{ml:1}} size='small' variant='contained' onClick={handleAprobar}>Aprobar</Button>
                    </Box>,
                sizes: {xs:12, sm:2}},
            {   
                label: (
                    <>
                        <AdjuntoToggle editable={editableParaAprobar} edit={adj_aprobar} onClick={handleAdj('adj_aprobar')}>
                            Archivos Para Aprobar
                        </AdjuntoToggle>
                    </>
                ), 
                children: <FileList 
                            folder="/comercial/pres"
                            docs={doc.para_aprobar} 
                            editable={adj_aprobar} 
                            downloadable={downloadable}
                            onUploaded={handleUpload('para_aprobar')}
                            onDelete={handleUploadDelete('para_aprobar')}
                        />,
                sizes: {xs:12, sm:10}
            },
            {   title: 'ARCHIVOS', sizes: {xs:12, sm:2}},
            {   
                label: (
                    <AdjuntoToggle editable={editable} edit={adj_presu} onClick={handleAdj('adj_presu')}>
                        Archivo Presupuesto
                    </AdjuntoToggle>
                ), 
                children: <FileList 
                            folder="/comercial/pres"
                            docs={doc.archivo_presupuesto} 
                            editable={adj_presu} 
                            downloadable={downloadable}
                            onUploaded={handleUpload('archivo_presupuesto')}
                            onDelete={handleUploadDelete('archivo_presupuesto')}
                        />, 
                sizes: {xs:12, sm:5}
            },
            {   
                label: (
                    <AdjuntoToggle editable={editable} edit={adj_arch} onClick={handleAdj('adj_arch')}>
                        Archivo Adjunto
                    </AdjuntoToggle>
                ), 
                children: <FileList 
                            folder="/comercial/adjuntos"
                            docs={doc.archivo_adjunto} 
                            editable={adj_arch} 
                            onUploaded={handleUpload('archivo_adjunto')}
                            onDelete={handleUploadDelete('archivo_adjunto')}
                          />, 
                sizes: {xs:12, sm:5}
            },
        ]
    )
    const showDoc = (
        <Box className={classes.root}>

            { withHeader && 
                <Box sx={{width: '100%'}}>

                    <Typography sx={{color: 'green', display: 'inline'}} variant='h6'>
                        Oportunidad {doc.nombre} &nbsp;
                        x {doc.valor} {doc.moneda} &nbsp;
                        ({doc.exito}%) est. {moment(doc.fecha_estimada).utc().format('YYYY-MM-DD')} &nbsp;
                        ({doc.status}
                            {doc.status === 'GANADA' && " el " + moment(doc.fecha_ganada).utc().format('YYYY-MM-DD') }
                            {doc.status === 'CERRADA' && " el " + moment(doc.fecha_cerrada).utc().format('YYYY-MM-DD') }
                            {doc.status === 'PERDIDA' && " el " + moment(doc.fecha_perdida).utc().format('YYYY-MM-DD') }
                        )
                        &nbsp;
                        <Box sx={{float: 'right'}}>
                            { doc.status === 'A PRESUPUESTAR' && <Button className={classes.perdida} onClick={handleChangeStatus} variant='outlined'>PERDIDA</Button>}
                            { doc.status === 'EN TRATATIVA'   && <Button className={classes.ganada} onClick={handleChangeStatus} variant='outlined'>GANADA</Button>}
                            { doc.status === 'EN TRATATIVA'   && <Button className={classes.perdida} onClick={handleChangeStatus} variant='outlined'>PERDIDA</Button>}
                            { doc.status === 'EN TRATATIVA'   && <Button className={classes.presupuestar} onClick={handleChangeStatus} variant='outlined'>A PRESUPUESTAR</Button>}
                            { doc.status === 'GANADA'         && <Button className={classes.ganada} onClick={handleChangeStatus} variant='outlined'>A CERRAR</Button>}                        
                        </Box>

                    </Typography>

                </Box>
            }

            <AttributesStyled
                sizes={{xs:6, sm:2}}
                list={getParams(doc)}
            />
        </Box>
    )
    return (
        <Box {...other}>
            {showDoc}

            {edit && <OpportunityModal docId={doc._id} onClose={() => setEdit(false)}/> }
        </Box>
    );
};

export default OpportunityShow;

