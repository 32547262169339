import React, {forwardRef, useEffect} from 'react'; 
import Box from '@mui/material/Box';
import {red, grey} from '@mui/material/colors';
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {DataGrid} from '../../components'; 

import moment from 'moment';

const columns = (isPlanning, onEdit, onDelete) => ([
    {
        field: 'articulo.marca',
        headerName: 'Marca',
        valueGetter: (params) => {
            return params.row.articulo && params.row.articulo.marca            
        }
    },
    {
        field: 'articulo.codigo',
        headerName: 'Código',
        valueGetter: (params) => {
            const art = params.row.articulo
            if (art) return art.codigo 
            return ""
        },
        width: 150,
    },
    {
        field: 'articulo.descripcion',
        headerName: 'Descripción',
        valueGetter: (params) => {
            const art = params.row.articulo
            if (art) return art.descripcion 
            return ""
        },
        width: 450,
    },
    {
        field: 'articulo_color',
        headerName: 'Color',
        type: 'string',
        width: 90,
    },
    {
        field: 'planificado',
        headerName: 'PLAN',
        type: 'number',
        renderCell: (params) => {
            if (params.value === 0) {
                return <Box className='red'>{params.value}</Box>
            }
            return params.value
        },
        width: 60,
    },
    {
        field: 'pedido',
        headerName: 'REQ',
        type: 'number',
        width: 60,
    },
    {
        field: 'recibido',
        headerName: 'REC',
        type: 'number',
        renderCell: (params) => {
            if (params.value > 0 && params.value < params.row.pedido) {
                return <Box className='red'>{params.value}</Box>
            }
            return params.value
        },
        width: 60,
    },
    {
        field: 'reservado',
        headerName: 'RES',
        type: 'number',
        width: 60,
    },
    {
        field: 'udm',
        headerName: 'UDM',
        type: 'string',
        width: 60,
    },
    {
        field: 'updated_pla',
        headerName: 'Ult. PLA',
        valueGetter: (params) => {
            return params.value !== null && moment(params.value).utc().format('YYYY-MM-DD')
        },
        width: 110,
    },
    {
        field: 'updated_req',
        headerName: 'Ult. REQ',
        valueGetter: (params) => {
            return params.value !== null && moment(params.value).utc().format('YYYY-MM-DD')
        },
        width: 110,
    },
    {
        field: 'updated_rec',
        headerName: 'Ult REC',
        valueGetter: (params) => {
            return params.value !== null && moment(params.value).utc().format('YYYY-MM-DD')
        },
        width: 110,
    },
    {
        field: 'actions',
        headerName: 'Acciones',
        type: 'actions',
        getActions: (params) => {
            const out = [
                <GridActionsCellItem icon={<CreateIcon/>} onClick={onEdit(params.row)} label="Edit" />,
            ]
            if (isPlanning) {
                out.push(<GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params.row)} label="Delete" showInMenu />)                
            }
            if (!isPlanning && params.row && params.row.recibido == 0 && params.row.reservado == 0) {
                out.push(<GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params.row)} label="Delete" showInMenu />)                
            }
            return out
        }
    }
])

const IndexPage = forwardRef( ({
        isPlanning=false,
        limit=100,
        rowCount=0, 
        rows=[], 
        loading=false,
        onPageChange=()=>{},
        onEdit=()=>{}, 
        onDelete=()=>{},
        ...other
    }, ref) => {

    const [rowCountState, setRowCountState] = React.useState(rowCount);
    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState,
        );
    }, [rowCount, setRowCountState]);

    return (
        <DataGrid
            loading={loading} 
            rows={rows} 
            rowCount={rowCountState}
            columns={columns(isPlanning, onEdit, onDelete)} 

            pagination
            paginationMode="server"
            pageSize={limit}
            onPageChange={onPageChange}

            rowHeight={35} 
            hideFooter={false} 
            sx={{
                'root.border': '2px solid red',
                'root.height': '100vh',
                '& div > .red': {
                    border: '3px solid ' + red[200]
                }
                
            }}
            
            {...other}
        />
    )
})

export default IndexPage;