import React, {useState} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import {postAPI, activityLog} from '../../utils/';
import {ParseCSV} from '../../components';

import _ from 'underscore';

const StockPlanningImportDialog = ({reference={}, open=true, onClose}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [progress, setProgress] = useState(false);

    const currentUser = useSelector(state => state.currentUser);

    const handleValidation = async (parsed) => {
        const data = parsed.data;
        const metaCol = [], errors = [], validation = {};

        const opts = {
            numColumns: 4,
            colsWithValues: [3]
        }
        const checkRow = (row, inx) => {
            if (row.length === 1 && !row[0]) return

            if (row.length !== opts.numColumns) {
                validation['col-num'] = validation['col-num'] || []
                validation['col-num'].push(inx + 1);
            }

            if ( !row[0] && !row[1] && !row[2] && !row[3] ) return

            if ( !row[0] || !row[3] ) {
                validation['vacio'] = validation['vacio'] || [];
                validation['vacio'].push(inx + 1);
            }
        }

        if (data.length > 0) {

            data.forEach(checkRow);

            const checkCodigo = (codigo) => {
              if (codigo[0] === "'") {
                return codigo.slice(1)
              }
              return codigo;
            }
            const artCodigos = data.map( it => checkCodigo(it[0]) );
            const articulos = await postAPI('/stock/parts/validate', {query: {activo:'SI', codigo: {$in: artCodigos}}, select: ['_id', 'codigo', 'deposito_udm']});
            const faltan = artCodigos.filter( it => !articulos.map(it1 => it1.codigo).includes(it) )
            
            data.forEach( (row, inx) => {
                const uno = articulos.filter(it1 => it1.codigo == checkCodigo(row[0]))
                if (uno && uno.length) {
                    row.push(uno[0]['deposito_udm'])
                    metaCol[inx] = {artId: uno[0]['_id']};
                } else {
                    metaCol[inx] = {error: "Articulo inactivo/inexistente!"}
                }
            })
            
            if (validation['col-num']) {
                errors.push( {
                    tipo: 'col-num',
                    rows: validation['col-num'],
                    msg: "Las filas " + validation['col-num'].join(', ') + " deben tener 4 columnas: CODIGO, DESCRIPCION, COLOR, REQUERIDO"
                });
            }
            if (validation['vacio']) {
                errors.push({
                    tipo: 'vacio',
                    rows: validation['vacio'],
                    msg: "Las filas " + validation['vacio'].join(', ') + " deben tener las columnas: CODIGO, DESCRIPCION, COLOR, REQUERIDO con valores"
                });
            }
        }
        return {metaCol, errors};
    }

    const handleProcessCSV = (parsed, metaCol, onProgress, cb) => {
        const data = parsed.data;
        if (!data) {
            enqueueSnackbar('No se parsea datos', {variant: 'error'});
            return
        }

        setProgress(true)

        // convierte array a hash
        const list = data.map( (row, inx) => {
            const fld = reference['tipo'] === 'PLAN' ? 'planificado' : 'pedido';
            const linea = {
                ...reference,
                articulo:       {_id: metaCol[inx]['artId']},
                articulo_color: row[2],
                [fld]:          row[3],
                udm:            row[4]
            }
            return linea
        }).filter((row, inx) => !metaCol[inx]['error'])
        debugger
        let url;
        if (reference['tipo'] === 'PLAN') {
            url = '/project/'+reference['obra']['_id']+'/materials/addList' 
        } else {
          url = '/work/workorders/'+reference['of']['_id']+'/materials/addList' 
        }
        postAPI(url, {ref: reference, list})
        .then(res => {
            setProgress(false);

            const msg = "Se agregó una nueva lista de requerimientos con " + list.length + " registros.";
            if (reference['tipo'] === 'PLAN') {
              activityLog({label: reference['obra']['nombre'], app:'obras', docId: reference['obra']['_id'], msg: msg, currentUser})
            } else {
              let app, label;
              if (reference['of'] && reference['of']['tipo'] === 'fabrica') {
                app = 'fabrica'; 
                label = 'OF-' + reference['of']['of'];  
              } else {
                app = 'servicio'; 
                label = 'OS-' + reference['of']['of'];  
              }
              activityLog({label, app, docId: reference['of']['_id'], msg: msg, currentUser})
            }

            enqueueSnackbar(msg, {variant: 'success'});

            if (cb(null, res) && onClose) {
                onClose()
            }
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        })
    }

    const transformCSV = (value, colNum) => {
      //debugger
      let out = value;
      if (_.isString(value)) {
        out = value.trim();
        if ( [5].indexOf(colNum) > -1 ) {
          out = out.replace(',','.')
        }
      }
      return out
    }

    return (
        <Dialog
          disableBackdropClick
          //disableEscapeKeyDown
          open={open}
          maxWidth='md'
          fullWidth={true}
          onClose={onClose}
        >
          <DialogTitle>Importación de Requerimientos</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Puede preparar un archivo de texto en una planilla de calculo con 4 columnas (código artículo, descripción, color, requerido). 
              Se va a borrar la lista anterior.
            </DialogContentText>
            { progress &&
              <div>
                <LinearProgress/>
                <Typography style={{textAlign:'center'}}>Espere mientras se termine este proceso</Typography>
              </div>
            }

            <ParseCSV
              open={true}
              withTitle={false}
              //withHeader={true}
              skipEmptyLines
              delimiter={"\t"} // es importante los curly!!!
              dynamicTyping={false}
              transform={transformCSV}
              onValidation={handleValidation}
              onProcess={handleProcessCSV}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} disable={!progress} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
    )
}


export default StockPlanningImportDialog;
