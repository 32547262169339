import React, {useState, useEffect, useMemo, useCallback} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import InputDropZone from '../../components/DropZone/InputDropZone';
import FileList from '../Attachments/FileList';
import {postAPI, activityLog} from '../../utils';
import moment from 'moment';

export const MeasurementForm = ({obraId, pvId, doc={}, refe={}, disabled=false, onReload=()=>{}, onCancel}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ fields, setFields ] = useState({});
    const [ archivo, setArchivo] = useState({});
    const currentUser = useSelector(state => state.currentUser);

    useEffect( () => {
        debugger
        setFields(doc)
    }, [doc])

    const handleChange = fld => (valor) => {
        setFields(v => ({...v, [fld]:valor}))
    }
    const handleUploadFile = uploadedFile => {
        debugger
        const obj = {
            obraId,
            pvId,
            approvedById: currentUser._id,
            archivo: uploadedFile,
            ...fields,
        }
        postAPI('/measurements/', obj)
        .then(async ({res, doc:doc1, num})=> {
          debugger
          let msg = `Se agregó una nueva medición "${uploadedFile.filename}". `

          setFields(v => ({}))
          enqueueSnackbar(msg, {variant: 'info'})

          if (num === 1) {
            await postAPI('/project/'+obraId, {status: 'F', fecha_medicion: new Date()})
            msg += "Se cambio de estado a F y se agrego fecha de medición."
            // activityLog({label: refe['obra'].nombre, app:'obras', docId: refe['obra']._id, msg: msg, currentUser})
          }
          activityLog({label: refe['obra'].nombre, app:'obras', docId: refe['obra']._id, msg, currentUser})

          if (onReload) onReload()
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        });  
    }
    const handleSubmit = (ev) => {
        if (!fields._id) return;
        debugger
        const obj = {
            ...fields
        }
        postAPI('/measurements/' + fields._id, obj)
        .then(async data => {
          debugger
          let msg = `Se editaron las Fecha Entrega "${moment(fields.fecha_entrega).format('YYYY-MM-DD')}" y Fecha Aprobación "${moment(fields.fecha_aprobacion).format('YYYY-MM-DD')}". `
        //   if (tipoAdjunto === 'archivo_medicion' && doc.status === 'SP') {
        //     await postAPI('/project/'+doc['_id'], {status: 'F', fecha_medicion: new Date()})
        //     msg += "Se cambio de estado a F y se agrego fecha de medición."
        //   }
        //   activityLog({label: doc.nombre, app:'obras', docId: doc._id, msg: msg, currentUser})

          setFields(v => ({}))
          enqueueSnackbar(msg, {variant: 'info'})
          activityLog({label: refe['obra'].nombre, app:'obras', docId: refe['obra']._id, msg, currentUser})
          if (onReload) onReload()
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        });  

    }

    const inputIsReady = !doc._id && !!fields['fecha_aprobacion'] && !!fields['fecha_entrega']

    const label = fields._id ? 'EDICION' : 'NUEVA';

return  <Box sx={{p:1}}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid sx={{width: '100%'}} container spacing={1}>
                        <Grid item xs={2}>
                            <Typography sx={{p:1}} variant='h6'>{label}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <DatePicker sx={{width: '100%'}} disabled={disabled} label='Fecha Aprobación' format="DD-MM-YYYY" value={fields['fecha_aprobacion'] && moment(fields['fecha_aprobacion']) ||""} onChange={handleChange("fecha_aprobacion")}/>
                        </Grid>
                        <Grid item xs={2}>
                            <DatePicker sx={{width: '100%'}} disabled={disabled} label='Fecha Entrega' format="DD-MM-YYYY" value={fields['fecha_entrega'] && moment(fields['fecha_entrega']) ||""} onChange={handleChange("fecha_entrega")}/>
                        </Grid>
                        <Grid item xs={4}>
                            {!fields._id && 
                                <InputDropZone 
                                    label="Arrastre o seleccione" 
                                    // withList 
                                    // multiple 
                                    folder={'/medicion'} 
                                    onUploaded={handleUploadFile}
                                    disabled={!inputIsReady}
                                />
                            }                     
                        </Grid>
                        <Grid item xs={2}>
                            {fields._id && <Button variant='outlined' onClick={handleSubmit}>Modificar</Button>}
                            {fields._id && <Button variant='' onClick={onCancel}>Cancelar</Button>}
                        </Grid>
                        {/* <Grid item xs={12}>
                            feidl{JSON.stringify(fields)}&nbsp;
                            doc{JSON.stringify(doc)}
                        </Grid> */}
                    </Grid>

                </LocalizationProvider>
            </Box>
}

export default MeasurementForm;