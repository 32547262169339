import React, {useState, useEffect, useMemo} from 'react';
import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { useParams, Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import StockPlanningShowEdit from '../Stock/StockPlanningShowEdit';
import TasksTable from '../Task/TasksTable';
import TabPanel from '../Project/TabPanel';
import WorkOrderTable from './WorkOrderTable';
import POrderTable from '../POrder/POrderTable';
import {Attributes, AdjuntoToggle} from '../../components';
import { postAPI, checkUserRoles, useTabs, defaultTheme, activityLog } from '../../utils';
import {SERVER_ATTACHMENT_PATH} from '../../appConfig.js';

import moment from 'moment'

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(
    (theme) => ({
      root: {
          color: '#333',
          backgroundColor: '#d7f1ff',
          padding: '10px',
      },
      ganada: {
          backgroundColor: '#8bc34a'
      },
      perdida: {
          backgroundColor: "#f20b56ed"
      },
      presupuestar: {
          backgroundColor: '#ffeb3b'
      },
      textoOverflow: {        
          overflow: 'hidden', 
          textOverflow: 'ellipsis', 
          maxWidth: '87%', 
          display: 'inline-block'
      },
      title: {
          fontSize: "14px",
          fontWeight: 800  
      },
      "@media print": {
          titleUnder: {
              fontSize: "14px",
              padding: "10px",
              width: "100%",
              borderBottom: '1px solid'
          },
      }
  }), { defaultTheme } )
  
const AttributesStyled = styled(Attributes)(
    ({ theme }) => ({
        ".MuiAttributes-title": {
            fontSize: "14px",
            fontWeight: 800,
        },
    }) 
)


const WorkOrderShow = ({
        doc={}, 
        edit=false,
        tipo='WORK-F',
        debug=false,
        onCancel=() => {},
        onReload=() => {},
        ...other
    }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [tab, setTab] = useState(0);
    const {appendTab} = useTabs();

    const currentUser = useSelector(state => state.currentUser);
    const org = useSelector(state => state.org);
    const hidden = !checkUserRoles(currentUser, ['local-admin'])
    const agregaTareas = ['SIN PROGRAMAR', 'PROGRAMADO', 'CONFIRMADO'].indexOf(doc['status']) > -1;
    
    const docLabel = doc && doc.tipo === 'fabrica' ? "OF" : "OS";

    const handleAddTask = async (task) => {
        task['of'] = {_id: doc._id};
        await postAPI('/task/' + task._id, task)
        const msg = "Se agregó la tarea '" + task.asunto + "' al servicio"
        activityLog({label:docLabel+'-'+doc.of, app: doc.tipo, docId:doc._id, msg, currentUser})
        enqueueSnackbar(msg, {variant: 'info'})
    }

    const handleRemoveTask = async (task) => {
        const tt = {of: 1}
        await postAPI('/task/unset/' + task._id, tt)
        const msg = "Se sacó la tarea '" + task.asunto + "' al servicio"
        activityLog({label:docLabel+'-'+doc.of, app: doc.tipo, docId:doc._id, msg, currentUser})
        enqueueSnackbar(msg, {variant: 'info'})
    }

    const handleAddOF = async (of) => {
        debugger
        of['of_rel'] = {_id: doc._id};
        await postAPI('/work/workorders/' + of._id, {of_rel: {_id: doc._id}})
        const msg = "Se agregó la OF " + of.of + " al servicio"
        activityLog({label:docLabel+'-'+doc.of, app: doc.tipo, docId:doc._id, msg, currentUser})
        enqueueSnackbar(msg, {variant: 'info'})
        if (onReload) onReload()
    }

    const handleRemoveOF = async (of) => {
        const msg = "Se sacó la OF "+ of.of + "al servicio"
        await postAPI('/work/workorders/unset/' + of._id, {of_rel: 1})
        activityLog({label:docLabel+'-'+doc.of, app: doc.tipo, docId:doc._id, msg, currentUser})
        enqueueSnackbar(msg, {variant: 'info'})
        if (onReload) onReload()
    }

    const handleChangeTab = (ev, newValue) => {
        setTab(newValue)
    }

    const formatFecha = (fecha) => {
        return fecha && moment(fecha).utc().format('YYYY-MM-DD')
    }

    let direccion = doc.obra && `${doc.obra.barrio} ${doc.obra.lote} ${doc.obra.direccion}`;
    if (doc.pv) {
        direccion = `${doc.pv.barrio} ${doc.pv.lote} ${doc.pv.direccion}`;
    }

    const planningDisabled = (tipo === 'WORK-F' ? ['SIN PROCESAR','PROCESADO','EN FABRICACION'] : ['SIN PROGRAMAR', 'PROGRAMADO', 'CONFIRMADO']).indexOf(doc.status) === -1

    const queryWork = doc.status !== 'SIN PROGRAMAR' && doc.ofs_rel && doc.ofs_rel.length ? 
        {
            docsDefault: doc.ofs_rel
        } 
        : 
        {
            obraId: doc.obra && doc.obra._id, 
            pvId: doc.pv && doc.pv._id, 
            ofId: doc._id,
            validateAdd: v => {return doc.ofs_rel.map(it => it._id).indexOf(v._id) === -1},
            // validateRemove: v => {return v.of && v.of._id === doc._id},
            onAdd: handleAddOF, 
            onRemove: handleRemoveOF
        }

    const medicionLink = doc && doc.measurement && doc.measurement.archivo &&
                        SERVER_ATTACHMENT_PATH + "/" + org + "/" + doc.measurement.archivo.path + doc.measurement.archivo.filename;
    
    let tabIndex = 0;
    return (
        <Box sx={{height: '100%', overflow: 'hidden'}} {...other}>
            <Tabs
                sx={{'@media print': {display: 'none'}}}
                centered
                value={tab} 
                onChange={handleChangeTab} 
            >
                <Tab id={"obra-0"}  label={"Detalle"} />
                <Tab id={"obra-2"}  label={"Materiales"} />
                {tipo !== 'WORK-F' && <Tab id={"obra-1"} label={"Tareas"} />}
                {tipo !== 'WORK-F' && <Tab id={"obra-3"} label={"Fabrica"} />}
                <Tab id={"obra-4"}  label={"Compras"} />
            </Tabs>

        
            <TabPanel sx={{overflow: 'auto', height: 'calc(100% - 230px)'}} index={tabIndex} value={tab}>
                <AttributesStyled 
                    sizes={{xs:6, sm:2}}
                    list={
                        [
                            (tipo !== 'WORK-F' && {title: 'LUGAR'}),
                            (tipo !== 'WORK-F' && {label: 'Dirección', text: direccion, sizes: {xs:4} }),
                            (tipo !== 'WORK-F' && {label: 'Cliente', text: doc.obra && doc.obra.cliente && doc.obra.cliente.fullname, sizes: {xs:3} }),
                            (tipo !== 'WORK-F' && {label: 'Teléfono', text: doc.obra && doc.obra.cliente && `${doc.obra.cliente.telefono} / ${doc.obra.cliente.celular}`, sizes: {xs:3} }),
    
                            (tipo !== 'WORK-F' && {title: 'TRANSPORTE'}),
                            (tipo !== 'WORK-F' && {label: 'Patente', text: doc.transporte && doc.transporte.patente }),
                            (tipo !== 'WORK-F' && {label: 'Nombre', text: doc.transporte && doc.transporte.nombre }),
                            (tipo !== 'WORK-F' && {sizes: {xs: 6} }),

                            {title: 'DETALLE'},
                            {label: tipo === 'WORK-F' ? 'Recurso' : 'Recursos', text: tipo === 'WORK-F' ? doc.recurso : doc.recursos},
                            {label: 'Categoria',        text: doc.categoria},
                            {label: 'Etapa',            text: doc.etapa },
                            {label: 'Cantidad',         text: doc.cantidad},
                            {label: 'UPS',              text: doc.ups },
                            {},
                            {label: 'Medición',         text: doc.measurement && doc.measurement.archivo && <a href={medicionLink} target="_blank">{doc.measurement.archivo.filename}</a>},
                            {label: 'Fecha Entrega',    text: doc.measurement && formatFecha(doc.measurement.fecha_entrega) },
                            {label: 'Fecha Aprobacion', text: doc.measurement && formatFecha(doc.measurement.fecha_aprobacion)},
                            {label: 'Reproceso',    text: doc.reproceso },
                            {},

                            {title: 'DESCRIPCION'},
                            {label: 'Descripcion',  text: doc.descripcion, sizes: {xs:6, sm:10}},

                            {title: 'REFERENCIAS'},
                            {label: 'Vendedor', text: doc.obra && doc.obra.ownerId && doc.obra.ownerId.username},
                            {label: 'Obra',     text: doc.obra && <a href="#" onClick={()=>appendTab('OBRA')(doc.obra)}>[{doc.obra.carpeta}] {doc.obra.nombre}</a>},
                            {label: 'PV',       text: doc.pv && <a href="#" onClick={()=>appendTab('PVTA')(doc.pv)}>[{doc.pv.pv}] {doc.pv.nombre}</a>},
                            {label: 'NPs',      text: doc.nps && (doc.nps || []).filter(ii => !!ii.np).map(ii => (
                                                    <a href="#" onClick={()=>appendTab('NP')(ii)}>
                                                        [{ii.np}/{ii.status}] {ii.provee.fullname}
                                                    </a>))},
                            {label: 'REL',      text: tipo === 'WORK-F' ?
                                        doc.of_rel && <a href="#" onClick={()=>appendTab('WORK-S')(doc.of_rel)}>{doc.of_rel.tipo === 'servicio' ? 'OS' : 'OF'}{doc.of_rel.of} ({doc.of_rel.status})</a> :
                                        doc.ofs_rel && doc.ofs_rel.map(ii => (<a href="#" onClick={()=>appendTab('WORK-F')(ii)}>{ii.tipo === 'servicio' ? 'OS' : 'OF'}{ii.of} ({ii.status})&nbsp;</a>))
                            },

                            {title: 'PLANIFICACION'},
                            {label: 'Plan Inicio',         text: formatFecha(doc.plan_fecha_inicio)},
                            {label: 'Plan Finalizacion',   text: formatFecha(doc.plan_fecha_finalizacion)},
                            {label: 'Plan Duración (d)',   text: doc.plan_duracion},
                            (tipo !== 'WORK-F' ? {label: 'OS Confirmado', text: formatFecha(doc.os_confirmado)} : {}),
                            (tipo !== 'WORK-F' ? {label: 'OS Programado', text: formatFecha(doc.os_programado)} : {}),
                            {},
                            {label: 'Fecha Inicio',         text: formatFecha(doc.fecha_inicio)},
                            {label: 'Fecha Finalizacion',   text: formatFecha(doc.fecha_finalizacion)},
                            {label: 'Duración (d)',         text: doc.duracion},
                            {},
                            {},
                            (tipo === 'WORK-F' && {title: 'ADMIN', hidden}),
                            (tipo === 'WORK-F' && {label: 'Fecha Retirado', text: formatFecha(doc.fecha_retirado)}),
                            // (tipo === 'WORK-F' && {label: 'Inicio Colocacion', text: formatFecha(doc.fecha_inicio_colocacion)}),
                            // (tipo === 'WORK-F' && {label: 'Fin Colocacion',    text: formatFecha(doc.fecha_fin_colocacion)}),
                        ]
                    }
                />
            </TabPanel>

            <TabPanel sx={{overflow: 'auto', height: 'calc(100% - 230px)'}} index={1} value={tab}>
                <StockPlanningShowEdit of={doc} disabled={planningDisabled}/>
            </TabPanel>

            {tipo === 'WORK-S' && 
            <TabPanel sx={{overflow: 'auto', height: 'calc(100% - 230px)'}} index={2} value={tab}>
                <TasksTable 
                    editable={doc.status === 'EN PROCESO'}
                    hideEdit={true}
                    validateAdd={v => {return !v.of}}
                    validateRemove={v => {return v.of && v.of._id === doc._id}}
                    onAdd={agregaTareas && handleAddTask}
                    onRemove={agregaTareas && handleRemoveTask}
                    reference={
                        {
                            label:  tipo === 'WORK-F' ? 'OF-' : 'OS-', app:tipo === 'WORK-F' ? 'fabrica' : 'servicio', 
                            field:  agregaTareas ? (doc.pv ? 'pv':'obraSchedule') : 'of', 
                            id:     agregaTareas ? (doc.pv ? doc.pv._id : doc.obra && doc.obra._id ) : doc._id, 
                            otherId: doc._id,
                            
                        }
                    }
                />
            </TabPanel>
            }

            {tipo === 'WORK-S' && 
                <TabPanel index={3} value={tab}>
                    <WorkOrderTable 
                        tipo={'fabrica'}
                        {...queryWork}
                        onClick={()=>()=>{}}
                    />
                </TabPanel>
            }

            <TabPanel index={tipo === 'WORK-F' ? 2 : 4} value={tab}>
                <POrderTable 
                    obraId={doc.obra && doc.obra._id} 
                    pvId={doc.pv && doc.pv._id} 
                    // onClick={handleClick('NP')}
                />
            </TabPanel>

        </Box>
    )
};

export default WorkOrderShow;

