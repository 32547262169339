import React, {useState, useEffect, useMemo, useCallback} from 'react';
import { useSnackbar } from 'notistack';
import Checkbox from '@mui/material/Checkbox';
import {Table} from '../../components';
import {getAPI} from '../../utils';
import moment from 'moment';

export const WorkOrderTable = ({obraId='', pvId='', ofId='', docsDefault=[], tipo='fabrica', onAdd, onRemove, validateAdd, validateRemove, onClick=()=>()=>{}}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [docs, setDocs] = useState(docsDefault);
    const [reload, setReload] = useState(false);

    const handleSelect = useCallback( (row) => (ev) => {
        onAdd(row);
        debugger
        setReload(true)
    }, [onAdd])
    const handleSelectNot = useCallback( row => async ev => {
        debugger
        onRemove(row);
        setReload(true)
    }, [onRemove])

    const cols = useMemo(() => [
        {   name: tipo === 'fabrica' ? "OF" :"OS", 
            field: v=> <a href='#' onClick={onClick(v['tipo']==='fabrica' ? 'WORK-F':'WORK-S')(v)}>{v['tipo']==='fabrica' ? "OF-" : "OS-"}{v['of']}</a>,           
            th: { align:'center'},
            td: { align:'center'}, 
        },
        {   name: 'Categoria', 
            field: v=> v['categoria'],    
            th: { align:'center'},
            td: { align:'center'}, 
        }, 
        {   name: 'Status',    
            field: v=> v['status'],       
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Inicio',    
            field: v=> moment(v['fecha_inicio'] ? v['fecha_inicio'] : v['plan_fecha_inicio']).utc().format('YYYY-MM-DD'), 
            th: {align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Finalizacion',    
            field: v=> moment(v['fecha_finalizacion'] ? v['fecha_finalizacion'] : v['plan_fecha_finalizacion']).utc().format('YYYY-MM-DD'), 
            th: {align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Cantidad (UPS)',    
            field: v=> v['cantidad'] + " (" + v['ups'] + ")", 
            th: {align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'OS',    
            field: v=> v['of_rel'] ? <a href='#' onClick={onClick(v['of_rel']['tipo']==='fabrica' ? 'WORK-F':'WORK-S')(v['of_rel'])}>{v['of_rel']['tipo']==='fabrica' ? "OF-" : "OS-"}{v['of_rel']['of']}</a> : "",           
            th: {align: 'center'},
            td: { align:'center'},
            hide: tipo === 'servicio' 
        },
        {   name: 'Agregar',
            field: v=>(validateAdd(v) && <Checkbox onClick={handleSelect(v)}/>),
            hide: !onAdd
        },
        {   name: 'Sacar',
            field: v=>(!validateAdd(v) && <Checkbox onClick={handleSelectNot(v)}/>),
            hide: !onRemove
        }

    ], [tipo, handleSelect, handleSelectNot, onAdd, onRemove, onClick])
    
    useEffect( () => {
        setReload(false);
        if (docsDefault.length) return;

        const q = {
            tipo,
            ofId
        }
        if (obraId) {
            q['queryName'] = 'byObras';
            q['obraId'] = obraId;
        }
        if (pvId) {
            q['queryName'] = 'byPv';
            q['pvId'] = pvId;
        }
        getAPI('/work/workorders/', q)
        .then(data => {
            if (data) setDocs(data.list)
        })
        .catch(err => {
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });   
    }, [obraId, pvId, ofId, tipo, docsDefault.length, reload, enqueueSnackbar])

    return (
        <Table 
            //sx={{width: "800px", p:1, m:1}}
            cols={cols}
            data={docs}
            tableProps={{size:'small', padding: 'normal', stickyHeader: true}}
            headerProps={{sx: {fontWeight:'bold'}}}
            rowProps={{sx: {m:1}}}
        />
    )
}

export default WorkOrderTable