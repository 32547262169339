import React, {createContext, useState, useMemo, useContext, useEffect, useCallback} from 'react';
import { useSelector, useDispatch } from "react-redux";

import useWebSocket, { ReadyState } from 'react-use-websocket';
import { getAPI } from '../../utils';
import moment from 'moment';

export const WebSocketContext = createContext(false, null, () => {})

export const WebSocketProvider = ({ children, ws_url }) => {
  const org = useSelector((state) => state.org);
  const currentUser = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        ws_url || "ws:example",
        {
            share: false,
            shouldReconnect: () => true,
        },
    )
    
  useEffect(() => {
        console.log("Connection state changed")
        if (readyState === ReadyState.OPEN) {
          sendJsonMessage({
            type: "subscribe",
            data: {
              channel: "general-chatroom",
            },
          })
        }
  }, [readyState])

  // Receive commands from server
  useEffect(() => {
      async function fetchData() {
        const {type, data} = lastJsonMessage || {};
        if (type === 'usersChannel') {
          if (data.logoutUser) {
            await getAPI('/logout/' + data.logoutUser )
          }
        }
      }
      fetchData()
      // console.log(`Got a new message: ${lastJsonMessage}`)
  }, [lastJsonMessage])

  const ret = [readyState, lastJsonMessage, sendJsonMessage]

  return (
    <WebSocketContext.Provider value={ret}>
      {children}
    </WebSocketContext.Provider>
  )
}
  
export const useChannel = (channelName="", onMessage=()=>{}) => {
    const [last, setLast] = useState(null)
    const [isReady, lastMessage, sendMessage] = useContext(WebSocketContext);
    useEffect( () => {
      if (lastMessage && lastMessage.type === channelName) {
        if (onMessage) {
          onMessage(lastMessage)
          setLast(lastMessage.data)
        }
      }
    }, [lastMessage])

    const sendToChannel = (msg) => {
      sendMessage({
        type: channelName,
        data: msg
      })
    }
    return [isReady, last, sendToChannel]
  }

  export const useUsersChannel = (onMessage=()=>{}) => {
    const noUserId = 'NO-USER';
    const [isReady, last, sendMessage] = useChannel('usersChannel', onMessage);
    
    const connectUser = useCallback( (org, token, currentUser) => {
      if (currentUser) {
        sendMessage({
          subscribe: true,
          org, 
          token,
          userId: currentUser._id, 
          username: currentUser.username, 
          initials: currentUser && currentUser.name && currentUser.surname &&(currentUser.name[0] + currentUser.surname[0])});
      }
    }, []);

    const onlineUsers = last ? last.users : {};

    const onlineByUsers = useMemo(() => {
      const countUsers = {};
      onlineUsers && Object.values(onlineUsers).map( cur => {
        if (cur.location) {
          countUsers[cur.username] = countUsers[cur.username] || {
            id: cur.username || noUserId, 
            username: cur.username, 
            initials: cur.initials, 
            location: cur.location, 
            sessions: 0, 
            activity: 0, 
            lastUpdate: null
          };
          countUsers[cur.username]['sessions'] += 1;
          countUsers[cur.username]['activity'] += cur.activity;
          if (cur.lastUpdate && !countUsers[cur.username]['lastUpdate']) {
            countUsers[cur.username]['lastUpdate'] = moment(cur.lastUpdate)
          }
          if (countUsers[cur.username]['lastUpdate'] && moment(cur.lastUpdate).diff(moment(countUsers[cur.username]['lastUpdate']), 'seconds') > 0) {
            countUsers[cur.username]['lastUpdate'] = moment(cur.lastUpdate);
          }
        }
      })
      // console.log('[websocket] countUsers', countUsers)
      return countUsers  
    }, [onlineUsers])

    const onlineByLocation = useMemo(() => {
      const countUsers = {};
      onlineUsers && Object.values(onlineUsers).map( cur => {
        if (cur.location) {
          countUsers[cur.location] = countUsers[cur.location] || {};
          countUsers[cur.location][cur.username] = countUsers[cur.location][cur.username] || {id: cur.username || noUserId, username: cur.username, initials: cur.initials, location: cur.location, sessions: 0, activity: 0}
          countUsers[cur.location][cur.username]['sessions'] += 1;
          countUsers[cur.location][cur.username]['activity'] += cur.activity;
        }
      })
      // console.log('[websocket] countUsers', countUsers)
      return countUsers  
    }, [onlineUsers])

    const reloadConfigs = useCallback( v => sendMessage({reloadConfigs: true}), []);
    const reloadUser = useCallback( id => sendMessage({reloadUser: id}), []);
    const logoutUser = useCallback( id => sendMessage({logoutUser: id}), []);
    const reportActivity = useCallback( (id, location, stat) => sendMessage({userId:id, location, activity: stat}), []);
    const isUserValid = useCallback( (id) => sendMessage({ping:true, userId:id}), []);

    return {
      isReady, 
      sendUserMessage: sendMessage, 
      reloadConfigs,
      reloadUser,
      logoutUser,
      connectUser,
      onlineUsers,
      onlineByUsers,
      onlineByLocation,
      reportActivity,
      isUserValid
    };
  }

  export default WebSocketProvider
  