import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { postAPI, reconnected } from '../../utils'
import Cookies from 'js-cookie';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import LinearProgress from '@mui/material/LinearProgress';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const Login = ({match, title="Ingreso a APP", defaultLocation="/"}) => {
  const [ error, setError ] = useState({})
  const [ fields, setFields ] = useState({})
  const [ withOrg, setWithOrg ] = useState(false)
  const [ auth, setAuth] = useState(false)
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar()

  console.log('RENDER LOGIN')

//   const currentOrg = useSelector( state => state.org);

  const cook = Cookies.get('org');

  useEffect( () => {
    let oo = ''
    if (cook) {
        oo = cook;
    }
    if (!oo && match && match.params) {
        oo = match.params.org;
    }
    if (oo) {
        setFields(f => ({...f, org: oo}))
    } else {
        setWithOrg(true)
    }
  }, [match, cook])

  const handleChange = (key) => (ev) => {
    ev.preventDefault();
    const vv = ev.target.value;
    fields[key] = vv;
    setFields(f => ({...fields, [key]: vv}));
  }
  const handleKeyDown = (ev) => {
      if (ev.code === 'Enter') {
          handleSubmit()
      }
  }
  const handleOrg = (ev) => {
    setWithOrg(true)
    setFields(f => ({...fields, username:'', password: ''}))
  }
  const handleCancel = (ev) => {
    setFields(f => ({...f, username:'', password: ''}))
  }
  const handleSubmit = () => {
    debugger
    const err = {org: '', username: '', password: ''}
    if (!fields['org']) {
        err['org'] = 'Falta empresa!'
    }
    if (!fields['username']) {
          err['username'] = 'Falta username!'
    }
    if (!fields['password']) {
        err['password'] = 'Falta contaseña!'
    }
    setError(err);
    if (err['org'] || err['username'] || err['password']) {
        return
    }
    const org = fields['org'].toLowerCase();
    setAuth(true);
    postAPI("/" + org + '/login', fields )
        .then(res => {
            reconnected(res)
            setAuth(false)
            enqueueSnackbar("Se loggeo correctamente", {variant: 'info'})

            navigate(defaultLocation, {replace: true})
        })
        .catch(err => {
            setError({title: err.message})
            setAuth(false)
            //console.log(err)
        });
  };

  return (
    <Box sx={{
        overflow: 'auto',
        //minWidth: 320,
        maxWidth: 500,
        bgcolor: 'background.paper',
        height: 'auto',
        position: 'absolute',
        top: '20%',
        left: 0,
        right: 0,
        margin: 'auto',
        borderRadius: '12px',
        boxShadow: 1,
    }}>
        <Card sx={{
            marginTop: 2,
            textAlign:'center',
        }}>
            <CardHeader 
                title={ title + (fields['org']? "/" + fields['org'] : "") }
            />

            <CardContent>
                { auth &&
                    <LinearProgress color="secondary" />
                }

                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                    <Box sx={{
                        margin: '15px 0'
                    }}>
                        <Typography sx={{color:'#f33'}}>
                            {error['title']}
                        </Typography>
                    </Box>

                    {withOrg && 
                     <TextField
                        sx={{margin:"10px", width: '300px'}}
                        label="Empresa"
                        value={fields['org']}
                        //inputRef={input => input && input.focus()}
                        fullWidth
                        onChange={handleChange("org")}
                        onKeyDown={handleKeyDown}
                        helperText={error['org']}
                     />
                    }

                    <TextField
                        sx={{margin:"10px", width: '300px'}}
                        label="Username"
                        value={fields['username']}
                        // inputRef={input => !fields['username'] && input && input.focus()}
                        fullWidth
                        onChange={handleChange("username")}
                        onKeyDown={handleKeyDown}
                        helperText={error['username']}
                    />

                    <TextField
                        sx={{margin:"10px", width: '300px'}}
                        label="Contraseña"
                        value={fields['password']}
                        type="password"
                        fullWidth
                        onChange={handleChange("password")}
                        onKeyDown={handleKeyDown}
                        helperText={error['password']}
                    />
                </form>
            </CardContent>

            <CardActions sx={{mb:3, display: 'block'}}>
    
                { !withOrg && 
                    <Button sx={{width: '100px', color: '#666', borderColor: '#888'}} disabled={auth} variant='outlined' onClick={handleOrg}>Cambiar</Button>
                }
                <Button sx={{width: '100px'}} disabled1={!auth} color='secondary' variant='outlined' onClick={handleCancel}>Cancelar</Button>

                <Button sx={{width: '100px'}} disabled={auth} color='primary' variant='outlined' onClick={handleSubmit}>Enviar</Button>            

            </CardActions>

        </Card>
    </Box>
  )
}

export const LoginPage = ({...other}) => {
    return (
            <Login
                withRemember withForgot
                defaultLocation={'/dashboard'}
                {...other}
            />
    )
}

export const ResetPasswordPage = ({match: {params}}) => {
    const {token} = params;
    debugger
    return <Login token={token} title='Reseteo de contraseña'/>
}

export const AccountsVerifyEmailPage = () => ""

export default Login
