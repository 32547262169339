import DialogContentText from '@mui/material/DialogContentText';
import React, {useState} from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import TransportForm from './TransportForm';
import {checkUserRoles} from "../../../utils";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalDialog({editDocId, onClose}) {
    const [edit, setEdit] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const currentUser = useSelector(state => state.currentUser);
    const disabled = !checkUserRoles(currentUser, ['local-admin', 'crm-edit'])
    const title = editDocId === 'new' ? "Nuevo Transporte" : (edit ? "Edición de Transporte" : "Transporte");

    const handleEdit = () => {
        setEdit(!edit)
    }

    return (
      <Dialog 
        fullScreen={fullScreen}
        maxWidth="lg"
        open={true} 
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{title}</Typography>
                <Button autoFocus color="inherit" disabled={disabled} onClick={handleEdit}>{!edit ? "Editar" : "Cancelar"}</Button>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>

        <DialogContent>
          
          <TransportForm editDocId={editDocId} onClose={onClose}/>

        </DialogContent>
      </Dialog>
  )
}
