import React, { useState, useEffect, useContext} from "react"; 
import { TabsContext } from './Layout/TabsProvider'; 
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { getAPI, reconnectNow } from '../utils'

import Button from '@mui/material/Button';

import { grey, red, green, blue, purple, yellow, orange } from '@mui/material/colors';
import {DashboardBase, NumberCard} from "../components";
import OpportunityIndex from "./Opportunity/OpportunityIndex";
import { ConnectedUsers } from "./Layout/ConnectedUsers";

const Dashboard = () => {
    const [data, setData] = useState("NOT CONNECTED");
    const [numUsers, setNumUsers] = useState(0);
    const [numContacts, setNumContacts] = useState(0);
    const [obras, setObras] = useState(0);
    const [oppos, setOppos] = useState({});
    const currentUser = useSelector( state => state.currentUser );
    const distributors = useSelector( state => state.distributors );
    const terminacionColores = useSelector( state => state.terminacionColores || [] );
    const resources = useSelector( state => state.resources || [] );
    const units = useSelector( state => state.units || [] );
    const warehouses = useSelector( state => state.warehouses || [] );
    const topics = useSelector( state => state.topics || [] );

    // const {curTab, dispatchTabs} = useContext(TabsContext);
    const dispatch = useDispatch();

    const isAdmin = true;

    useEffect( () => {
        getAPI('/check_server')
            .then(res => setData(res.express))
            .catch(err => console.log(err));
        getAPI('/settings/users/count')
            .then(res => setNumUsers(res.num))
            .catch(err => {
                console.log(err)
            })
        getAPI('/crm/contacts/count')
            .then(res => setNumContacts(res.num))
            .catch(err => {
                console.log(err)
            })
            debugger
        getAPI('/crm/oppos/stats')
            .then(res => setOppos(res.oppos))
            .catch(err => {
                console.log(err)
            })
        getAPI('/project/stats')
            .then(res => setObras(res.stat))
            .catch(err => {
                console.log(err)
            })
    }, [])

    // const handleClick = (tab, query) => ev => {
    //     if (tab === 'OPORTUNIDADES') {
    //         const action = {
    //             label: 'OPORTUNIDADES',
    //             component: <OpportunityIndex tipo='OPPO'/>,
    //             canClose: false,
    //             default: true
    //         }
    //         dispatchTabs({type: 'addTab', ...action})
    //         dispatch({type: 'FILTERS-MERGE', oppos: {responsable, status, revision}})
    //     }
    // }

    return (
        <DashboardBase 
            title=""
            items={[
                {
                    component: <NumberCard title='CRM' label='Contactos' value={numContacts} color={blue}/>,
                    sizes: {xs:6, sm:2}
                },
                {
                    component: <NumberCard title='CRM' label='Opos ganadas YTD' value={oppos.ganadas_ytd} color={green}/>,
                    sizes: {xs:6, sm:2}
                },
                {
                    component: <NumberCard title='CRM' label='Opos ganadas YTD-1' value={oppos.ganadas1_ytd} color={green}/>,
                    sizes: {xs:6, sm:2}
                },
                // {
                //     component: <NumberCard title='OBRAS' label='Sin Planilla' value={obras.sp} color={orange}/>,
                //     sizes: {xs:6, sm:2}
                // },
                // {
                //     component: <NumberCard title='OBRAS' label='En Fabricacion' value={obras.f} color={orange}/>,
                //     sizes: {xs:6, sm:2}
                // },
                // {
                //     sizes: {xs:6, sm:2}                    
                // },
                // {
                //     component: isAdmin && <NumberCard title='Admin' label='Colores' value={terminacionColores.length} color={orange}/>,
                //     sizes: {xs:6, sm:2}
                // },
                // {
                //     component: isAdmin && <NumberCard title='Admin' label='Recursos FAB' value={resources.fabrica && resources.fabrica.length} color={blue}/>,
                //     sizes: {xs:6, sm:2}
                // },
                // {
                //     component: isAdmin && <NumberCard title='Admin' label='Recursos SERVICIO' value={resources.servicio && resources.servicio.length} color={blue}/>,
                //     sizes: {xs:6, sm:2}
                // },
                // {
                //     component: isAdmin && <NumberCard title='Admin' label='Almacenes' value={warehouses.length} color={green}/>,
                //     sizes: {xs:6, sm:2}
                // },
                // {
                //     component: isAdmin && <NumberCard title='Admin' label='Unidades' value={units.length} color={green}/>,
                //     sizes: {xs:6, sm:2}
                // },
                // {
                //     component: isAdmin && <NumberCard title='Admin' label='Topicos' value={topics.length} color={green}/>,
                //     sizes: {xs:6, sm:2}
                // },
                // {
                //     component: <Button onClick={reconnectNow}>Reconnect</Button>
                // },
            ]}
        />
    )
}

export default Dashboard;