import React, {forwardRef, useState, useEffect, useCallback, useContext, useImperativeHandle} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import {GridActionsCellItem} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import ExitIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import {PageBase, PageTitle, DataGrid, SearchBox, MessageBox, ExportButton} from '../../components'; 
import {UserSearchBox} from '../Settings/Users/UserSearchBox';
import {getAPI, deleteAPI, useTabs, checkUserRoles, activityLog, debounce} from '../../utils' ;
import {EXCEL_LIMIT} from '../../appConfig';
import ActivityOpenClose  from '../Activities/ActivityOpenClose';

import moment from 'moment';

const queryOptions = [
    {label: 'TODOS', value:"-1"},
    {label: 'Corregir Telefonos', value: 'checkTel'},
    {label: 'Corregir email', value: 'checkEmail'},
    {label: 'Corregir apellido', value: 'checkSurname'},
]

const sortOptions = [
    {label: 'Razon Social/Apellido', value:"surname"},
    {label: 'Oportunidades', value: 'oppos'},
]


const columns = (provee, esLocalAdmin, onClick, onDelete) => ([
    {
        field: 'codigo_proveedor',
        headerName: 'Codigo',
        renderCell: (params) => {
            const nombre = params.value;
            if (provee === '0') {
                return <Link href="#" onClick={onClick('CONTACT')(params.row)}>{nombre}</Link>            
            } else {
                return <Link href="#" onClick={onClick('PROVEE')(params.row)}>{nombre}</Link>            
            }
        }
    },
    {
        field: 'tipo',
        headerName: 'Tipo',
        sortable: false,
        width: 150,
    },
    {
        field: 'razon_social',
        headerName: 'Razon Social/Apellido',
        sortable: false,
        renderCell: (params) => {
            const nombre = params.value || params.row.apellido;
            if (provee === '0') {
                return <Link href="#" onClick={onClick('CONTACT')(params.row)}>{nombre}</Link>            
            } else {
                return <Link href="#" onClick={onClick('PROVEE')(params.row)}>{nombre}</Link>            
            }
        },
        width: 220,
    },
    {
        field: 'nombre',
        headerName: 'Nombre',
        sortable: false,
        renderCell: (params) => {
            const nombre = params.value;
            if (provee === '0') {
                return <Link href="#" onClick={onClick('CONTACT')(params.row)}>{nombre}</Link>            
            } else {
                return <Link href="#" onClick={onClick('PROVEE')(params.row)}>{nombre}</Link>            
            }
        },
        width: 150,
    },
    // {
    //     field: 'fullname',
    //     headerName: 'Nombre Completo',
    //     sortable: false,
    //     renderCell: (params) => {
    //         const nombre = params.value;
    //         if (provee === '0') {
    //             return <Link href="#" onClick={onClick('CONTACT')(params.row)}>{nombre}</Link>            
    //         } else {
    //             return <Link href="#" onClick={onClick('PROVEE')(params.row)}>{nombre}</Link>            
    //         }
    //     },
    //     width: 250,
    // },
    {
        field: 'email',
        headerName: 'Email',
        type: 'string',
        width: 170,
    },
    {
        field: 'telefono',
        headerName: 'Tel',
        type: 'string',
        width: 170,
    },
    {
        field: 'celular',
        headerName: 'Celular',
        type: 'string',
        width: 170,
    },
    {
        field: 'telefono1',
        headerName: 'Teléfono o Cel.',
        sortable: false,
        width: 150,
        valueGetter: (params) => {
            const c = params.row.celular;
            const t  = params.row.telefono;
            return c || t 
        }
    },
    {
        field: 'ownerId',
        headerName: 'Responsable',
        type: 'string',    
        valueGetter: (params) => {
            const own = params.value;
            return own ? own.username : "";
        },
        hide: provee === '0',
        width: 130,
   },
   {
        field: 'activo',
        headerName: 'Activo',
        type: 'boolean',    
        valueGetter: (params) => {
            return params.value === 'SI'
        },
        width: 70,
    },
    {
        field: 'numOppos',
        headerName: 'numOppos',
        description: 'Total Oportunidades',
        hide: provee === '1',
        width: 70,
    },
    {
        field: 'ganOppos',
        headerName: 'ganOppos',
        description: 'Oportunidades Ganadas',
        hide: provee === '1',
        width: 70,
    },
    {
        field: 'numPO',
        headerName: 'PO',
        hide: provee === '0',
        width: 70,
    },
    {
        field: 'priceList',
        headerName: 'PL',
        renderCell: (params) => {
            const link = <IconButton size='small' onClick={onClick('PL')(params.row)}>
                            <ExitIcon fontSize='10'/>
                        </IconButton>
            return <span>{params.row.pList} {link}</span>;          
        },
        hide: provee === '0',
        width: 90,
    },
    {
        field: 'updated_at',
        headerName: 'Actualizado',
        //type: 'date',
        valueGetter: (params) => {
            return moment(params.value).utc().format('YYYY-MM-DD')
        },
        width: 120,
    },
    // {
    //     field: 'actions',
    //     headerName: 'Acciones',
    //     type:'actions',
    //     getActions: (params) => [
    //       //<GridActionsCellItem icon={<CreateIcon/>} onClick={onClick('CONTACT')(params.row)} label="Edit" />,
    //       <GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params.row)} label="Delete" showInMenu />,
    //     ]
    // }
])

const IndexPage = forwardRef( ({provee="0", isTabSelected}, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  let [searchParams] = useSearchParams();
  const [filter, setFilter]   = useState(searchParams.get("filter") || "");
  const [tipo, setTipo]       = useState(searchParams.get("tipo") || "-1");
  const [users, setUsers]     = useState(searchParams.get("users") || "-1");
  const [consulta, setConsulta] = useState(searchParams.get("users") || "-1");
  const [sort, setSort] = useState(searchParams.get("sort") || "surname");
  const [reload, setReload]   = useState(false);
  const [dialog, setDialog]   = useState(false);
  const [num, setNum] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const limit = 100;

  const currentUser = useSelector(state => state.currentUser);
  const esLocalAdmin = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'crm-edit', 'compras-edit'])

  const optionsAll = useSelector(state => state.options) || {contactTypes: [], firmTypes: []};
  const tipoOptions = [{label: 'TODOS', value: -1}, ...optionsAll['contactTypes'], ...optionsAll['firmTypes'] ];

  const {appendTab} = useTabs({
    onReload: (reload) => {setReload(reload)}
  });

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    getAlert() {
        alert("getAlert from Child");
    }
  }));  

  const fn = useCallback(
    debounce(
        (filter, users, tipo, consulta, sort, page, limit, provee, enqueueSnackbar) => {
            setReload(false)
            setLoading(true)
            getAPI('/crm/contacts/', {filter, users, tipo, consulta, sort, skip: page*limit, limit, provee})
            .then(data => {
                setLoading(false);
                data.list.forEach( it => it.id = it._id);
                setNum(data.num)
                setRows(data.list)
            })
            .catch(err => {
                setLoading(false);
                console.error(err)
                enqueueSnackbar(err.message, {variant: "error"})
            });
        }, 500)
  , [])
  useEffect( () => {
    fn(filter, users, tipo, consulta, sort, page, limit, provee, enqueueSnackbar)
  }, [filter, users, tipo, consulta, sort, page, limit, provee, reload, isTabSelected, enqueueSnackbar])

  const handleDelete = (item) => {
      setLoading(true)
      deleteAPI('/crm/contacts/' + item._id)
      .then(data => {
          setLoading(false);
          debugger
          setReload(true);
          const doc = data.doc;
          const label = doc.es_provee ? "[" + doc.codigo_proveedor + "] " + doc.fullname : doc.fullname;

          const msg = 'Se borró el contacto ' + data.doc.fullname;
          activityLog({label, app:'contactos', docId: item._id, msg, currentUser})
          enqueueSnackbar("Se borró el registro correctamente", {variant: "info"})
      })
      .catch(err => {
          setLoading(false);
          console.error(err)
          enqueueSnackbar(err.message, {variant: "error"})
      });
  }

  const handleFilter = (ev) => {
      setFilter(ev.target.value)
  }

  const handleUsers = (ev)=> {
      setUsers(ev.target.value)
  }  
  
  const handleClick = tipo => item => (ev) => {
    debugger
      if (item.id) appendTab(tipo)(item)
  }
  const handleConsulta = ev => {
    setConsulta(ev.target.value)
  }
  const handleTipo = ev => {
    setTipo(ev.target.value)
  }
  const handleSort = ev => {
    setSort(ev.target.value)
  }

  const handleReload = (reload) => {
      setReload(reload)
  }

  const configReport = useCallback( async () => {
    const esContacto = provee === '0';
    const reportConfig = {
        title: `Contactos`,
        subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
        cols: [
                !esContacto && {title: 'Codigo', field: 'codigo_proveedor'},
                esContacto && {title: 'Tipo', field: 'tipo'},
                {title: 'Razon Social', field: '_', transform: (v, doc) => {
                    if (doc.razon_social) {
                        return doc.razon_social;
                    } else {
                        return doc.apellido;
                    }
                }},
                {title: 'Nombre', field: 'nombre'},
                {title: 'Email', field: 'email'},
                {title: 'Telefono', field: 'telefono'},
                {title: 'Celular', field: 'celular'},
                {title: 'Responsable', field: '_', transform: (v, doc) => doc.ownerId && doc.ownerId.username},
                {title: 'Activo', field: '_', transform: (v, doc) => doc.activo ? '1' : '0'},
                esContacto && {title: 'Oportunidades #', field: 'numOppos'},
                esContacto && {title: 'Oportunidades Ganadas', field: 'ganOppos'},
                {title: 'Creado', field: 'created_at', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
                {title: 'id', field: '_id'}
        ],
        styles: {}
    }

    const rows = await getAPI('/crm/contacts/', {filter, users, tipo, consulta, sort, skip: page*limit, limit: 3*EXCEL_LIMIT, provee})
        
    reportConfig['rows'] = rows.list;

    return reportConfig;
  }, [filter, users, tipo, consulta, sort, provee]);

  const columnVisibilityModel = {
    codigo_proveedor: provee === '1',
    ownerId: provee === '0',
    numOppos: provee === '0',
    ganOppos: provee === '0',
    numPO: provee === '1',
    priceList: provee === '1',
    tipo: provee === '0',
    actions: !!esLocalAdmin
  }

  return (
      <PageBase 
          sx={{width:'100%', height: 'calc(100% - 95px)'}} 
          title={
            <>
                <Hidden smDown>
                    <PageTitle>{provee==='1' ? "Proveedores" : "Contactos"} ({num})</PageTitle>            
                </Hidden>
            </>
          }
          toolbar={
            <Box>
                <SearchBox sx={{ml:1}} label="Filtro" value={filter} onChange={handleFilter}/>
                <Hidden mdDown>
                    { 
                        provee === '0' &&
                        <UserSearchBox label="Responsable" defaultValue={users} onChange={handleUsers}/>
                    }
                    {
                        provee === '0' &&
                        <SearchBox label="Tipo" select options={tipoOptions} value={tipo} onChange={handleTipo}/>
                    }
                    <SearchBox label="Consultas" select options={queryOptions} value={consulta} onChange={handleConsulta}/>
                    <SearchBox label="Orden" select options={sortOptions} value={sort} onChange={handleSort}/>
                </Hidden>
            </Box>
          }
          actions={
            <Box sx={{display: 'flex'}}>
                { esLocalAdmin && provee === "0" && <Button sx={{m:1}} size='small' variant='outlined' onClick={handleClick('CONTACT')({id: true, _id: 'new', provee: '0'})}>Nuevo</Button> }
                { esLocalAdmin && provee === "1" && <Button sx={{m:1}} size='small' variant='outlined' onClick={handleClick('PROVEE')({id: true, _id: 'new', provee: '1'})}>Nuevo</Button> }
                <ExportButton configReport={configReport}>EXCEL</ExportButton>
                <ActivityOpenClose app='contactos' withLabel/>
            </Box>
          }      
     >
          <DataGrid
              loading={loading} 
              rows={rows} 
              rowCount={num}
              columns={columns(provee, esLocalAdmin, handleClick)} 
              columnVisibilityModel={columnVisibilityModel}

              pagination
              paginationMode="server"
              pageSize={limit}
              onPageChange={(newPage) => setPage(newPage)}
  
              rowHeight={35} 
              hideFooter={false} 
              sx={{
                  'root.border': '2px solid red',
                  'root.height': '100vh'
              }}
        />    
      </PageBase>
  )
})

export default IndexPage;