import React, {useState, useEffect} from 'react';
import { SnackbarProvider } from 'notistack';
import { Provider as ReduxProvider } from "react-redux";
import { Box } from '@mui/system';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
//import DateAdapter from '@mui/lab/AdapterMoment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { appReduxStore, defaultTheme } from './utils';
import { AppRouter } from './ui';
import { WebSocketProvider } from './ui/Layout/WebSocketProvider.jsx';

import "./App.css"

import './utils/array.js'

import {routes, WS_URL} from './appConfig.js';


const App = () => {

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider maxSnack={3}>  
            <ReduxProvider store={appReduxStore}>
              <WebSocketProvider ws_url={WS_URL}>      
                <ThemeProvider theme={defaultTheme}>

                  <Box sx={{  height: "100%", overflow: 'hidden'}}>
                    <AppRouter routes={routes()}/>
                  </Box>
                  
                </ThemeProvider>
              </WebSocketProvider>
            </ReduxProvider>
          </SnackbarProvider>
        </LocalizationProvider>
    );
}

export default App;