import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {useSelector} from "react-redux";

import Button from '@mui/material/Button';
import IconButton from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import {checkUserRoles} from "../utils";

const MyMenu = ({
    menu={}, 
    currentUser,
    buttonProps={},
    iconButtonProps={color: 'primary'}
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);
    const handleOpen = (event) => {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLink = (link) => () => {
        setAnchorEl(null);
        navigate(link, {replace: true})
    };

    return (
        <div>
            <Button 
                sx={{ml: '10px', minWidth: '20px', padding: '6px 4px'}}
                id="basic-button"
                onClick={handleLink(menu.link)}
                startIcon={menu.icon}
            >
                <Typography sx={{display: { xs: 'none', md: 'flex', ...buttonProps } }}>{menu.button}</Typography>
            </Button>
            
            { menu && menu.menu && (
                <>
                    <IconButton 
                        sx={{pt: '0.35rem', ml: '-0.2rem'}}
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleOpen}
                        onMouseOver={handleOpen}
                    >
                        { open ? <ExpandLessIcon sx={iconButtonProps}/> : <ExpandMoreIcon sx={iconButtonProps}/>}
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            onMouseLeave: handleClose,
                        }}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}        
                    >
                        { menu.menu.map( it => checkUserRoles(currentUser, it.roles) && (
                            <MenuItem key={it.text} onClick={handleLink(it.link)}>
                                {it.icon && <ListItemIcon>{it.icon}</ListItemIcon>}
                                <ListItemText>{it.text}</ListItemText>
                                <Typography variant="body2" color="text.secondary">
                                    {it.shortcut}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}
        </div>
    );
}

const StackMenu = ({menu=[], direction='row', ...other}) => {
    const currentUser = useSelector(state => state.currentUser);
    return (
        <Stack spacing={2} direction={direction}>
            {
                menu.map( it => 
                    checkUserRoles(currentUser, it.roles) && 
                        <MyMenu 
                            key={it.link}
                            menu={it} 
                            currentUser={currentUser} 
                            {...other}
                        />
                )
            }
        </Stack>
    )
}

export default StackMenu;
  