import React from 'react';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const PageBase = ({title='', actions='', toolbar='', paperProps={}, children, ...other}) => {

    return (
        <Paper sx={{height: "calc(100% - 48px)", ...paperProps}} {...other} >
            <Box sx={{
                    paddingLeft: "0.5rem", 
                    marginBottom: '10px', 
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                    {/* Title */}
                    <Box sx={{alignSelf: 'center'}}>
                        <h2>{title}</h2>
                    </Box>

                    {/* Center toolbar */}
                    <Box
                        sx={{
                            alignSelf: "center",
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {toolbar}
                    </Box>
                    
                    {/* Action toolbar */}
                    <Box sx={{alignSelf: 'center'}}>{actions}</Box>
            </Box>
            <Box sx={{clear:'both', height: 'calc(100% - 60px)', padding: '0 10px'}}>
                {children}
            </Box>
        </Paper>
    )
}

export default PageBase;