import React, {useState, useEffect} from 'react'; 
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {PageBase, PageTitle, SearchBox, DataGrid} from '../../../components';
import {checkUserRoles} from "../../../utils";
import { useSelector } from "react-redux";
import {getAPI, deleteAPI} from '../../../utils' ;
import { useSnackbar } from 'notistack';
import ActivityOpenClose  from '../../Activities/ActivityOpenClose';
import UserEditModal from './UserEditModal';
import { useUsersChannel } from '../../Layout/WebSocketProvider';

const columns = (onEdit, onDelete, isAdmin) => ([
  {
    field: 'id',
    headerName: 'ID',
    type: 'number'
  },
  {
    field: 'username',
    headerName: 'Username',
    type: 'string',    
    width: 110,
  },
  {
    field: 'fullname',
    headerName: 'Nombre',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
  },
  {
    field: 'email',
    headerName: 'Email',
    type: 'string',    
    flex: 0.5,
  },
  {
    field: 'active',
    headerName: 'Active',
    type: 'boolean',    
    width: 80,
  },
  {
    field: 'distributorIds',
    headerName: 'Distribuidores',
    valueGetter: (params) => params.value.map(it => it.codigo).join(', '),
    width: 100,
  },
  {
    field: 'roles',
    headerName: 'Roles',
    flex: 1,
    minWidth: 200,
    valueGetter: (params) => params.value.join(', ')
  },
  {
    field: 'actions',
    headerName: 'Acciones',
    type:'actions',
    getActions: (params) => {
      const out = [
        <GridActionsCellItem icon={<CreateIcon/>} onClick={onEdit(params)} label="Edit" />
      ]
      if (isAdmin) {
        out.push(<GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params)} label="Delete" showInMenu />)
      }
      return out;
    }
  }
])

const IndexPage = () => {
    let [searchParams] = useSearchParams();
    const [filter, setFilter]   = useState(searchParams.get("filter") || "");
    // const [editDoc, setEditDoc] = useState("");
    const [reload, setReload]   = useState(false);

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    //const [rows, setRows] = useState([]);
    const [users, setUsers] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const { reloadConfigs } = useUsersChannel();
    const limit = 100;
    
    const currentUser = useSelector(state => state.currentUser);
    const notDisabled = checkUserRoles(currentUser, ['local-admin'])
    const isAdmin     = checkUserRoles(currentUser, ['admin'])
    const [editId, setEditId] = useState("");

    //let user = searchParams.get("status");
 
    useEffect( () => {
        setReload(false)
        setLoading(true)

        getAPI('/settings/users/', {filter, skip: page*limit, limit})
        .then(data =>  {
            setLoading(false);
            // data.users.forEach( it => { it.id = it._id; } );
            setUsers(data.users)
            setNum(data.num)

        })
        .catch(err => {
            console.error(err)
            enqueueSnackbar(err.message, {variant: 'error'})
        });

    }, [page, filter, reload, enqueueSnackbar])

    const handleFilter = (ev) => {
        setFilter(ev.target.value)
    }
    const handleEdit = (item) => (ev) => {
        delete item.row.password
        setEditId(item.row._id)
    }

    const handleDelete = (params) => (ev) => {
      const id = params.row._id
      deleteAPI('/settings/users/' + id)
        .then(res => {
            console.log(res)
            enqueueSnackbar("Se eliminó el usuario correctamente", {variant: 'info'});
            reloadConfigs()
            setReload(true)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: 'error'})
        });   
      setEditId("")
      setReload(true)
    }

    const handleNew = (ev) => {
      setEditId('new')
      // let params = useParams();
      // let id = editDoc ? editDoc.id : params.id;
    }

    const handleClose = (obj) => {
      debugger
      if (obj) {
        setReload(true)
      }
      setEditId("")
  }

    return (
        <PageBase
            title={
                <>
                    <PageTitle>Usuarios ({num})</PageTitle> 
                    <SearchBox label="Filtro" value={filter} onChange={handleFilter}/>
                </>
            }
            actions={
                <>
                    <Button sx={{m:1}} size='small' onClick={handleNew} variant='outlined'>{notDisabled ? 'Nuevo' : ""}</Button>
                    <ActivityOpenClose app={'usuario'} withLabel/>
                </>
            }    
        >
            <DataGrid
                loading={loading} 
                rows={users} 
                rowCount={num}
                columns={columns(handleEdit, handleDelete, isAdmin)} 

                pagination
                paginationMode="server"
                pageSize={limit}
                onPageChange={(newPage) => setPage(newPage)}
    
                rowHeight={35} 
                hideFooter={false} 
                sx={{
                    'root.border': '2px solid red',
                    'root.height': '100vh'
                }}
              
            />    

            { editId && <UserEditModal  editId={editId} edit={true} onClose={handleClose}/> }    

        </PageBase>
    )
}

export default IndexPage;