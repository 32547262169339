import { appReduxStore } from '../utils'

import querystring from 'querystring';

/*
function encodeQueryData(data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
}
*/
export const callAPI = async (url, opts={
    method:'GET', 
    mode: 'cors',
    headers: {
        'Content-Type': 'application/json'
    }}) => {
        if (opts.body) {
            opts['method'] = 'POST'
        }
        try {
            return await fetch('/api' + url, opts)
                        .then(async response => {
                            if (response.status === 401 || response.status === 403) {
                                console.log("API - unauthorized!!")
                                // appReduxStore.dispatch({
                                //     type: "LOGOUT"
                                // })
                                appReduxStore.dispatch({
                                    type: "LOGIN",
                                    user: "",
                                    expires: "",
                                    org: ""
                                })
                                return
                                // throw new Error("Sin autorización para acceder!") 
                            }
                            if (response.status === 400) {
                                const body = await response.json()
                                throw new Error(body.error.message) 
                            }
                            if (response.status === 404) {
                                throw new Error("La ruta de acceso es desconocida: " +  url) 
                            }
                            if (response.status >= 500) {
                                throw new Error("Error de conexion con el servidor!") 
                            }
                
                            if (!response.ok) {
                                throw new Error('Ocurrio un error desconocido!');
                            }
                            return response.json()
                        })

        } catch (error) {
            // Check for network-related errors
            if (error instanceof TypeError && error.message.includes('Failed to fetch')) {
                console.error('Network error:', error.message);
                // Handle network error specific logic here
                // For example, retry logic, logging, or informing the user
            } else {
                // Handle other types of errors
                console.error('Error:', error.message);
            }
            throw error; // Rethrow the error after handling it
        }
}

export const getAPI = async (url, params) => {
    if (params) {
        url += "?" + querystring.stringify(params)
    }
    return await callAPI(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
}

export const postAPI = async (url, data) => {
    return await callAPI(url, {
        method: "POST", 
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
}
export const deleteAPI = async (url) => {
    const q = {
        method: "DELETE", 
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        }
    }
    // if (data) {
    //     q['body'] =  JSON.stringify(data)       
    // }
    return await callAPI(url, q)
  }
  
export const reconnectAPI = async () => {
    return getAPI("/reconnect")
}
