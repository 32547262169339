import React, { useState, useEffect } from 'react';
// import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import InputIcon from '@mui/icons-material/Input';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { checkUserRoles } from '../../utils';


export const POrderDetail = React.forwardRef(function POrderDetail(props, ref) {
    const { doc={}, 
        pendiente=false, 
        disabled=false, 
        onEditLine=()=>{}, 
        onDeleteLine=()=>{}, 
        appendTab,
        withActions=true, withRecs=true, 
        ...other } = props;

    // const { enqueueSnackbar } = useSnackbar();
    const [sumRows, setSumRows] = useState({});
    // const currentUser = useSelector(state => state.currentUser);
  
    const artKey = (it) => {return it.pedidolog_ref}

    useEffect( () => {
        if (!doc || !doc.detail) return;
debugger
        const tt = {};
        doc.detail.forEach( it => {
            const key = artKey(it);
            tt[key] = tt[key] || {np: 0, rec: 0, open: false}
            if (it.tipo === 'REC') {
                tt[key]['rec'] += Number(it.recibido_depo) || 0;
            }
            if (it.tipo === 'NP') {
                tt[key]['np'] += Number(it.pedido_depo) || 0;
            }
        })
        setSumRows(tt)
    }, [doc])
    
    const detail = () => {
        if (!doc || !doc.detail) return [];
        return doc.detail.filter(it=>it.tipo === 'NP').sort(it => it.linea);
    }

    const getTotales = (row, tipo) => {
        const key = artKey(row);
        const el = sumRows[key];
        return el ? el[tipo] : ""
    }

    const getRecs = (row) => {
        if (!doc || !doc.detail) return [];
        return doc.detail.filter(it => it.tipo === 'REC' && 
                //it.articulo_color === row.articulo_color && 
                //it.articulo._id === row.articulo._id &&
                it.pedidolog_ref === row.pedidolog_ref
        )
    }
    const handleToggleDetail = (row) => (ev) => {
        debugger
        const key = artKey(row)
        const nuevo = sumRows[key]
        nuevo['open'] = !nuevo['open']
        setSumRows(t => ({ ...t, [key]: nuevo}))
    }

    const handleShowRec = (item) => (ev) => {
        debugger
        const rec = item.pedido_padre;
        appendTab('REC')(rec);
    }
    const editStatusEnabled = doc.tratamiento ? ['PARA PEDIR'] : ['PARA PEDIR', 'RECIBIDO CON PENDIENTE'];
    
    const headers = {
        backgroundColor: '#aaa',
        fontWeight: 600
    }
    const ellipsis = {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    }
    return ( 
        <Box {...other}>
            <TableContainer sx={{height: '100%'}} component={Paper}>
                <Table sx={{ minWidth: 650 }} size='small' padding='small' stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{...headers, width:'20px'}} align='center'>Linea</TableCell>
                            {doc.tipo !== 'VARIOS' && <TableCell sx={{...headers, width: '80px'}} align="left">Código</TableCell>}
                            {doc.tipo === 'VARIOS'  && <TableCell sx={{...headers}} align="left">Artículo</TableCell>}
                            {doc.tipo === 'MATERIALES' && <TableCell sx={{...headers}} align="left">Artículo</TableCell>}
                            {doc.tipo === 'MATERIALES' && <TableCell sx={{...headers, width: '200px'}} align="left">Color</TableCell>}
                            {doc.tipo === 'MATERIALES' && <TableCell sx={{...headers, width: '30px'}} align="right">Ped</TableCell>}
                            {doc.tipo === 'MATERIALES' && <TableCell sx={{...headers, width: '30px'}} align="right">UDM</TableCell>}
                            {doc.tipo === 'VIDRIOS' && <TableCell sx={{...headers}} align="left">Medida</TableCell>}
                            <TableCell sx={{...headers, width: '30px'}} align="right">P</TableCell>
                            <TableCell sx={{...headers, width: '30px'}} align="right">R</TableCell>
                            <TableCell sx={{...headers, width: '30px'}} align="right">UDM</TableCell>
                            <TableCell sx={{...headers}} align="right">Precio Unit</TableCell>
                            <TableCell sx={{...headers}} align="right">Total</TableCell>
                            {withActions && <TableCell sx={{...headers}} align="right">Acción</TableCell>}
                        </TableRow>
                    </TableHead>
                    
                    <TableBody>
    
                    {detail().map((row) => {
                        const recs = getRecs(row);
                        if (pendiente && getTotales(row, 'rec') === row.pedido_depo) return "";
                        const faltaDatos = !row.moneda || !row.precio;
                        return (
                            <>
                                <TableRow
                                    key={row.name + "-0"}
                                    //sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    sx={{'& td': {border: faltaDatos ? "1px solid red" : 0, padding: '0px 5px'}}}
                                >
                                    <TableCell align="center">
                                        <Box sx={{display: 'inline'}}>
                                            {row.linea}
                                            {withRecs && sumRows[artKey(row)] && sumRows[artKey(row)]['rec'] > 0 && 
                                             <IconButton size='small' onClick={handleToggleDetail(row)}>
                                                {!!sumRows[artKey(row)]['open'] ? 
                                                    <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>
                                                }
                                             </IconButton>
                                            }
                                        </Box>
                                    </TableCell>
                                    {doc.tipo !== 'VARIOS' && <TableCell>{row.vidrio ? row.vidrio.codigo : row.articulo.codigo}</TableCell>}
                                    <TableCell sx1={{...ellipsis}} align="left">{doc.tipo === 'MATERIALES' ? row.articulo.descripcion : row.descripcion}</TableCell>
                                    {doc.tipo === 'MATERIALES' && <TableCell align="left">{row.articulo_color}</TableCell>}
                                    {doc.tipo === 'MATERIALES' && <TableCell align="right">{row.pedido}</TableCell>}
                                    {doc.tipo === 'MATERIALES' && <TableCell align="right">{row.udm}</TableCell>}
                                    <TableCell align="right">{row.pedido_depo}</TableCell>
                                    <TableCell align="right">{getTotales(row, 'rec')}</TableCell>
                                    <TableCell align="right">{row.udm_depo}</TableCell>
                                    <TableCell align="right">{Number(row.precio).toFixed(3)} {row.moneda}</TableCell>
                                    <TableCell align="right">{Number(row.precio*row.pedido).toFixed(1)} {row.moneda}</TableCell>
                                    {withActions && <TableCell align="right">
                                        <IconButton onClick={onEditLine(row)} disabled={disabled || editStatusEnabled.indexOf(doc.status) === -1} size='small'><EditIcon/></IconButton>
                                        <IconButton onClick={onDeleteLine(row)} disabled={disabled || doc.status !== 'PARA PEDIR'} size='small'><DeleteIcon/></IconButton>
                                    </TableCell>}
                                </TableRow>

                                {doc.tipo === 'VIDRIOS' &&
                                    <TableRow
                                        key={row.name + "-1"}
                                        //sx={{ '&:last-child td, &:last-child th': { border: 2 } }}
                                    >
                                        <TableCell align="right" colSpan={2}></TableCell>
                                        <TableCell align="left" colSpan={2}>{row.articulo_color}</TableCell>
                                        <TableCell align="rit" colSpan={5}></TableCell>
                                    </TableRow>
                                }
                                {false && doc.tipo === 'VIDRIOS' &&
                                    <TableRow>
                                        <TableCell align="right" colSpan={1}></TableCell>
                                        <TableCell align="right" colSpan={6}>{JSON.stringify(row.vidrio)}</TableCell>
                                    </TableRow>
                                }

                                {getTotales(row, 'open') && 
                                <TableRow
                                    key={row.name + "-2"}    
                                    //sx={{ '&:last-child td, &:last-child th': { border: 2 } }}                            
                                >
                                    <TableCell colSpan={1}/>

                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                        <Collapse in={getTotales(row, 'open')} timeout="auto" unmountOnExit>
                                            <Box sx={{ margin: 1 }}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                RECEPCIONES
                                            </Typography>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                <TableRow>
                                                    <TableCell>Recepción</TableCell>
                                                    <TableCell>Recibido</TableCell>
                                                    <TableCell>Remito</TableCell>
                                                    <TableCell align="right">Cantidad</TableCell>
                                                    <TableCell align="right">UDM</TableCell>
                                                </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {
                                                    recs.map(rec => (
                                                        <TableRow key={rec.fecha_recibido}>
                                                            <TableCell><a href="#" onClick={handleShowRec(rec)}>REC #{rec.pedido_padre && rec.pedido_padre.rec} </a></TableCell>
                                                            <TableCell>{rec.pedido_padre && rec.pedido_padre.fecha_recibido}</TableCell>
                                                            <TableCell>{rec.pedido_padre && rec.pedido_padre._id && rec.pedido_padre.remito}</TableCell>
                                                            <TableCell align="right">{rec.recibido}</TableCell>
                                                            <TableCell align="right">{rec.udm}</TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                                {
                                                    recs.length === 0 && 
                                                    <TableRow key='123'>
                                                        <TableCell/>
                                                        <TableCell/>
                                                        <TableCell>No hay recepciones</TableCell>
                                                        <TableCell/>
                                                        <TableCell/>
                                                    </TableRow>
                                                }
                                                </TableBody>
                                            </Table>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                                }
                            </>
                            
                        )}
                    )}

                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    )
})

export default POrderDetail;

export const ROrderDetail = ({sx={}, doc={}}) => {      
    const headers = {
        backgroundColor: '#aaa',
        fontWeight: 600
    }
    const tipo = doc.pedido_orig && doc.pedido_orig.tipo;
    const esVidrio = tipo === 'VIDRIOS'
    return ( 
        <Box sx={sx}>
            <TableContainer sx={{height: '100%'}} component={Paper}>
                <Table sx={{ minWidth: 650 }} size='small' padding='none' stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{...headers, width:'90px'}} align='center'>Linea</TableCell>
                            {tipo !== 'VARIOS' && <TableCell sx={{...headers}} align="left">Código</TableCell>}
                            {tipo !== 'VARIOS' && !esVidrio && <TableCell sx={{...headers}} align="left">Artículo</TableCell>}
                            {tipo !== 'VARIOS' && !esVidrio && <TableCell sx={{...headers}} align="left">Color</TableCell>}
                            {tipo !== 'VARIOS' && esVidrio && <TableCell sx={{...headers}} align="left">Medida</TableCell>}
                            {tipo === 'VARIOS' && <TableCell sx={{...headers}} align="left">Artículo</TableCell>}
                            {esVidrio && <TableCell sx={{...headers}} align="left">Tipo</TableCell>}
                            {!esVidrio && <TableCell sx={{...headers}} align="right">Recibido</TableCell>}
                            {!esVidrio && <TableCell sx={{...headers}} align="right">UDM</TableCell>}
                            <TableCell sx={{...headers}} align="right">Recibido Depo</TableCell>
                            <TableCell sx={{...headers}} align="right">UDM</TableCell>
                        </TableRow>
                    </TableHead>
                    
                    <TableBody>
    
                    {(doc.detailRec || []).map((row) => (
                        <>
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="center">{row.pedidolog_ref && row.pedidolog_ref.linea}</TableCell>
                                {tipo !== 'VARIOS' && <TableCell>{row.vidrio ? row.vidrio.codigo : row.articulo && row.articulo.codigo}</TableCell>}
                                {tipo !== 'VARIOS' && <TableCell align="left">{!row.vidrio ? row.articulo && row.articulo.descripcion : row.descripcion}</TableCell>}
                                {tipo !== 'VARIOS' && <TableCell align="left">{row.articulo_color}</TableCell>}
                                {tipo === 'VARIOS' && <TableCell align="left">{row.descripcion}</TableCell>}
                                {!row.vidrio && <TableCell align="right">{row.recibido}</TableCell>}
                                {!row.vidrio && <TableCell align="right">{row.udm}</TableCell>}
                                <TableCell align="right">{row.recibido_depo}</TableCell>
                                <TableCell align="right">{row.udm_depo}</TableCell>
                            </TableRow>
                        </>
                    ))}

                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    )
}