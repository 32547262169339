import React from 'react'
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';

import { AutocompleteBase, AutocompleteObjectInput } from '../../components'

const POrderAutocomplete = ({withLink=false, helperText, onInputChange, onClick=()=>{}, ...props}) => {

    const codigo = (it) => {
        if (!it) return "";
        const pp1 = it.provee && it.provee.fullname
        return `NP-${it.np} ${pp1} (${it.status})`;
    }
    const getOptionLabel = (it) => {
        return codigo(it);
    }
    const isOptionEqualToValue = (option, value) => {
        return codigo(option) === codigo(value)
        //return option && value && option._id === value._id
    }
    return (
        <Box>
            <AutocompleteBase
                sx={{width: (withLink ? '85%' : '100%'), display: 'inline-block'}}
                label="Compra"

                autocomplete_method='/stock/porders/autocomplete'
                //autocomplete_params={{provee}}
                getOptionLabel={getOptionLabel}
                renderOption={(props, option) => <li {...props}>{codigo(option)}</li> }
                isOptionEqualToValue={isOptionEqualToValue}
                disableClearable={false}
                errorText={helperText}
                onInputChange={onInputChange}
                {...props}
            />
            {withLink && <IconButton sx={{verticalAlign: 'bottom'}} onClick={onClick}><LinkIcon fontSize="small"/></IconButton>}
        </Box>
    )
}

export default POrderAutocomplete;
    
export const POrderAutocompleteObjectInput = ({...other}) => {
    return (
        <AutocompleteObjectInput 
            {...other} 
            Component={POrderAutocomplete}
        />
    )
}
