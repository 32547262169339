import React, {useState, useEffect, useMemo, useCallback} from 'react';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TaskEdit from './TaskEdit';
import TaskStatusChange from './TaskStatusChange';
import {Table} from '../../components';
import TabPanel from '../Project/TabPanel';

import {getAPI} from '../../utils';
import moment from 'moment';

const TasksTabs = ({tab, editable, docs, cols, reference, hideEdit, handleChangeTab, onAfterSubmit}) => {
    if (!editable) {
        return (
            <Table
                //sx={{width: "800px", p:1, m:1}}
                cols={cols}
                data={docs}
                tableProps={{size:'small', padding: 'normal', stickyHeader: true}}
                headerProps={{sx: {fontWeight:'bold'}}}
                rowProps={{sx: {m:1}}}
            />
        )
    }
    return (
        <>
            <Tabs
                sx={{'@media print': {display: 'none'}}}
                centered
                value={tab} 
                onChange={handleChangeTab} 
            >
                <Tab label={"Lista"} />
                {!hideEdit && 
                    <Tab disabled={!editable} label={"Nueva Tarea"} />
                }
                {/* {editable && <Tab label={"Nueva Solicitud"} />} */}
            </Tabs>

            <TabPanel index={0} value={tab} sx={{height: 'calc(100% - 220px)', overflow:'auto'}}>
                <Table
                    //sx={{width: "800px", p:1, m:1}}
                    cols={cols}
                    data={docs}
                    tableProps={{size:'small', padding: 'normal', stickyHeader: true}}
                    headerProps={{sx: {fontWeight:'bold'}}}
                    rowProps={{sx: {m:1}}}
                />
            </TabPanel>

            <TabPanel index={1} value={tab} sx={{height: 'calc(100% - 220px)'}}>
                {!hideEdit && 
                    <TaskEdit reference={reference} onAfterSubmit={onAfterSubmit}/>
                }
            </TabPanel>
        </>
    )
}

const TasksTable = ({ 
    reference, 
    validateAdd=()=>{}, validateRemove=()=>{}, 
    onAdd, onRemove, 
    hideEdit=false, 
    hideRefs=false,
    editable=false, 
    ...other 
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [docs, setDocs] = useState([])
    const [reload, setReload] = useState(false);
    const [tab, setTab] = useState(0);

    const refes = (row, onClick) => {
        const out = []
        if (row.obra) {
            const tag = <Chip size='small' onClick={onClick('OBRA')(row.obra)} label={"OB-" + row.obra.carpeta + " " + row.obra.nombre}/>
            out.push( tag )
        }
        if (row.pv) {
            const tag = <Chip size='small' onClick={onClick('PVTA')(row.pv)} label={"PV-" + row.pv.pv + " " + row.pv.nombre}/>
            out.push( tag )
        }
        if (row.of) {
            const tipo = row.of.tipo === 'fabrica' ? "OF-" : "OS-";
            const tag = <Chip size='small' onClick={onClick('WORK-F')(row.of)} label={tipo + row.of.of}/>
            out.push( tag )
        }
        if (row.np) {
            const tag = <Chip size='small' onClick={onClick('NP')(row.np)} label={"NP-" + row.np.np}/>
            out.push( tag )
        }
        return <Box sx={{overflow: 'hidden'}}>{out}</Box>
    }
    
    const handleSelect = useCallback( (row) => (ev) => {
        onAdd(row);
        debugger
        setReload(true)
    }, [onAdd])
    const handleSelectNot = useCallback( row => async ev => {
        debugger
        onRemove(row);
        setReload(true)    
    }, [onRemove])

    const cols1 = useMemo(() => [
        {   name: 'Tablero',    
            field: v=> v['tasklist'] && `${v['tasklist']['boardlist']['name']}:${v['tasklist']['name']}`,       
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {   name: "Asunto", 
            field: v=> v['asunto'],           
            th: { align:'center'},
            td: { align:'left'}, 
        },
        {   name: 'Status',    
            field: v=> <TaskStatusChange doc={v} disabled={!editable} onChange={ev => setReload(true)}/>,       
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Referencias',    
            field: v => refes(v, ()=>()=>{}),
            hide: !!hideRefs,
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Creado Por',    
            field: v=> v['ownerId'] && `${v['ownerId']['username']}`,       
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Creado',    
            field: v=> v['created_at'] && `${moment(v['created_at']).utc().format("YYYY-MM-DD")}`,       
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {
            name: 'Agregar',
            field: v=>(validateAdd(v) && <Checkbox onClick={handleSelect(v)}/>),
            hide: !onAdd
        },
        {
            name: 'Sacar',
            field: v=>(validateRemove(v) && <Checkbox onClick={handleSelectNot(v)}/>),
            hide: !onRemove
        }
    ], [handleSelect, handleSelectNot, validateAdd])

    const cols2 = useMemo(() => [
        {   name: 'Status',    
            field: v=> v['status'],       
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Referencias',    
            field: v => refes(v, ()=>()=>{}),       
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Creado Por',    
            field: v=> v['ownerId'] && `${v['ownerId']['username']}`,       
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Creado',    
            field: v=> v['created_at'] && `${moment(v['created_at']).utc().format("YYYY-MM-DD")}`,       
            th: { align: 'center'},
            td: { align:'center'}, 
        },
    ], [])

    useEffect( () => {
        setReload(false);
debugger
        const q = {};
        const {label, app,  field, id, otherId} = reference;
        if (field === 'obra') {
            q['queryName'] = 'byObras';
            q['obraId'] = id;
        }
        if (field === 'obraSchedule') {
            q['queryName'] = 'byObrasSchedule';
            q['obraId'] = id;
            q['ofId'] = otherId;
        }
        if (field === 'of') {
            q['queryName'] = 'byOf';
            q['ofId'] = id;
        }
        if (field === 'pv') {
            q['queryName'] = 'byPv';
            q['pvId'] = id;
        }
        getAPI('/task/', q, {sort: {updated_at: -1}})
        .then(data => {
            if (data) setDocs(data.list)
        })
        .catch(err => {
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });   
    }, [reference, reload, enqueueSnackbar])

    const handleChangeTab = (ev, newValue) => {
        setTab(newValue)
    }


    return (
        <Box sx1={{height:'100%', overflow:'auto'}} {...other}>

            <TasksTabs 
                tab={tab}
                editable={editable}
                docs={docs}
                cols={cols1}
                reference={reference}
                handleChangeTab={handleChangeTab}
                hideEdit={hideEdit}
                onAfterSubmit={() => setReload(true)}
                {...other}
            />

        </Box>
    )
}

export default TasksTable;