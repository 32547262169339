import React, {useState} from 'react'
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import { AutocompleteBase, AutocompleteObjectInput } from '../../components'
import ContactEditModal from './ContactEditModal'

const ContactAutocomplete = ({withAdd=false, provee='0', helperText, onInputChange, ...props}) => {
    const [add, setAdd] = useState(false);

    const nombreApellido = (it) => {
        if (!it || !Object.keys(it).length) return "";
        return provee === '0' ? it.fullname : it.codigo_proveedor + " / " + it.fullname;
    }
    const getOptionLabel = (it) => {
        return nombreApellido(it);
    }
    const isOptionEqualToValue = (option, value) => {
        return nombreApellido(option) === nombreApellido(value)
        //return option && value && option._id === value._id
    }
    return (
        <Box sx={{width:'100%', display: 'inline-flex'}}>
            <AutocompleteBase
                sx={{width: (withAdd ? '85%' : '100%'), display: 'inline-block'}}
                label={provee === '0' ? "Contacto" : "Proveedor"}

                autocomplete_method='/crm/contacts/autocomplete'
                autocomplete_params={{provee}}
                getOptionLabel={getOptionLabel}
                renderOption={(props, option) => <li {...props}>{nombreApellido(option)}</li> }
                isOptionEqualToValue={isOptionEqualToValue}
                disableClearable={false}
                errorText={helperText}
                onInputChange={onInputChange}
                {...props}
            />
            {withAdd && <IconButton sx={{verticalAlign: 'bottom'}} onClick={() => setAdd(true)}><PersonAddIcon/></IconButton>}
            {add && <ContactEditModal editDoc={{_id: 'new'}} provee="0" onClose={() => setAdd(false)}/>}
        </Box>
    )
}

export default ContactAutocomplete;
    
export const ContactAutocompleteObjectInput = ({...other}) => {
    return (
        <AutocompleteObjectInput 
            {...other} 
            Component={ContactAutocomplete}
        />
    )
}
