import React, {useEffect, useState} from 'react';
import {blue, green, red, yellow} from '@mui/material/colors';
import {DashboardBase, NumberCard, ExportButton} from '../components';
import {getAPI} from '../utils' 

const ejemplo = () => {
    const styles = {
        head1:  {color:"red", width: '30%', textAlign: 'center'},
        number: {textAlign:'right'} 
    }
    const cols = [
        {title: 'Uno', field: 'name', headClassName: 'head1', rowClassName: 'row'},
        {title: 'DOS', field: 'valor', headClassName: 'head1', rowClassName: 'number'}
    ]
    const rows = [
        {name: 'Martin'},
        {name: 'Juan', valor:123}
    ]
    return {styles, cols, rows}
}

const DashPage = () => {
    const [loading, setLoading] = useState(false);
    const [stat, setStat] = useState({
        po:{
            para_pedir:0,
            para_aprobar:0,
            rec_pend:0,
            rec_pend_60:0,
        }
    })

    useEffect( () => {
        setLoading(true)
        getAPI('/stock/porders/stats', {})
        .then(data => {
            setLoading(false);
            setStat(data)
        })
        .catch(err => {
            console.error(err)
        });
    }, [])

    const handleClick = () => {

    }

    const items = [
        {
            component: <NumberCard title="NP" label="PARA PEDIR" value={stat.po.para_pedir} color={blue} onClick={handleClick('OPORTUNIDADES', {status: 'EN TRATATIVA'})} sizes={{xs:6,sm:3,md:2}}/>,
            sizes: {xs:6, sm:2}
        },
        {
            component: <NumberCard title="NP" label="PARA APROBAR" value={stat.po.para_aprobar} color={green} onClick={handleClick('OPORTUNIDADES', {status: 'EN TRATATIVA'})} sizes={{xs:6,sm:3,md:2}}/>,
            sizes: {xs:6, sm:2}
        },
        {
            component: <NumberCard title="NP" label="RECIBIDO CON PEND" value={stat.po.rec_pend} color={red} onClick={handleClick('OPORTUNIDADES', {status: 'EN TRATATIVA'})} sizes={{xs:6,sm:3,md:2}}/>,
            sizes: {xs:6, sm:2}
        },
        {
            component: <NumberCard title="NP" label="RECIBIDO CON PEND 60d" value={stat.po.rec_pend_60} color={red} onClick={handleClick('OPORTUNIDADES', {status: 'EN TRATATIVA'})} sizes={{xs:6,sm:3,md:2}}/>,
            sizes: {xs:6, sm:2}
        },
        {
            component: <ExportButton reportName="PRUEBA" cols={ejemplo().cols} styles={ejemplo().styles} rows={ejemplo().rows}>EXCEL</ExportButton>
        }
    ]
    return (
        <DashboardBase 
            items={items}
            loading={loading}
        />
    )
}

export default DashPage;