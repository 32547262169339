import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers'
import {PageBase, PageTitle, Table, SearchBoxSmall, ExportButton, SelectField} from '../../components'; 
import {ProjectSearchBox} from '../Project/ProjectAutocomplete';
import {getAPI, postAPI, debounce, checkUserRoles, useTabs} from '../../utils' ;
import {EXCEL_LIMIT} from '../../appConfig';
import { InputTextField } from './InputTextField';
import StockMaterialDialog from './StockMaterialDialog';
import moment from 'moment';

const optionsStatus = [
    {label: 'Todos', value: -1}, 
    {label: 'Planificado', value: 'plan'}, 
    {label: 'Finalizado', value: 'fin'}
]

const optionsReserva = [
    {label: 'Todos', value: -1},
    {label: 'Sin Reserva', value: 0},
    {label: 'Con Reserva', value: 1}
]

const optionsColor = [
    {label: 'Todos', value: -1},
    {label: 'Sin Color', value: 0},
    {label: 'Con Color', value: 1}
]

const optionsTipoMov = [
    {label: 'ENVIO', value:"ENV"},
    {label: 'DEVOLUCION', value:"DEV"},
    {label: 'RESERVA', value:"RES"},
    {label: 'DES-RESERVA', value:"DRES"},
]

const optionsOrden = [
    {label: 'Categoria', value: 'categoria'}, 
    {label: 'Marca', value: 'marca'}, 
    {label: 'Articulo', value: 'articulo'}
]
  
const columns = (modo, valores, errores, handleValores, handleInventory) => ([
    {
        name: 'OF',
        field: v => {
            const of = v.of;
            const tipo = of.tipo === 'fabrica' ? 'OF' : 'OS'
            return of ? `[${tipo}-${of.of}] ${of.status}` : "";
        },
        width: 150,
    },
    // {
    //     name: 'Obra',
    //     field: v => v.of && v.of.obra && `[${v.of.obra.carpeta}] ${v.of.obra.status}`,
    //     width: 90,
    // },
    // {
    //     name: 'PV',
    //     field: v => v.of && v.of.pv && `[${v.of.pv.pv}] ${v.of.pv.status}`,
    //     width: 90,
    // },
    {
        name: 'Obra/PV',
        field: v => v.of && v.of.pv ? `[PV-${v.of.pv.pv}] ${v.of.pv.status}` : `[OB-${v.of.obra.carpeta}] ${v.of.obra.status}`,
        width: 90,
    },
    {
        name: 'Categoria',
        field: v => v.articulo.categoria,
        width: 100,
    },
    {
        name: 'Marca',
        field: v => v.articulo.marca,
    },
    {
        name: 'Código',
        field: v => v.articulo.codigo,
        width: 150,
    },
    {
        name: 'Descripción',
        field: v => v.articulo.descripcion,
    },
    {
        name: 'Color',
        field: v => v.articulo_color,
    },
    {
        name: 'REQ',
        field: v => v.pedido,
        td: { align:'right'}, 
    },
    {
        name: 'REC',
        field: v => v.recibido,
        td: { align:'right'}, 
    },
    {
        name: 'ENV',
        field: v =>     v.pedido > v.recibido && 
                        // valores[v.id] > 0 &&
                        v.inventory && v.inventory.length > 0 ? 
        //(v.pedido - v.recibido <= v.inventory[v.inventory_sel || 0].dispo + v.reservado) ? 
                        <InputTextField
                            value={valores[v.id]} 
                            error={!!errores[v.id]}
                            label={errores[v.id]} 
                            onChange={handleValores(v)}
                        /> : "",
        hide: modo !== 'ENV',
        td: { align:'right'}, 
    },
    {
        name: 'DEV',
        field: v =>     v.recibido > 0 ? 
                        <InputTextField
                            value={valores[v.id]} 
                            error={!!errores[v.id]}
                            label={errores[v.id]} 
                            onChange={handleValores(v)}
                        /> : "",
        hide: modo !== 'DEV',
        td: { align:'right'}, 
    },
    {
        name: 'a RES',
        field: v =>     v.reservado == 0 &&
                        v.inventory && v.inventory[v.inventory_sel] &&
                        v.pedido - v.recibido > 0 &&
                        (v.pedido - v.recibido) <= v.inventory[v.inventory_sel].dispo ? 
                        <Checkbox
                            // sx={{width: '80px'}} 
                            // checked={!!valores[v.id]} 
                            checked={valores[v.id] && valores[v.id] > 0} 
                            onChange={handleValores(v)}
                            error={!!errores[v.id]}
                            label={errores[v.id]} 
                        /> : "",
        hide: modo !== 'RES',
        td: { align:'right'}, 
    },
    {
        name: 'de RES',
        field: v =>     v.reservado > 0 ? 
                        <Checkbox
                            // sx={{width: '80px'}} 
                            // checked={!!valores[v.id]} 
                            checked={valores[v.id] && valores[v.id] > 0} 
                            onChange={handleValores(v)}
                            //disabled={true}
                            //error={!!errores[v.id]}
                            //label={errores[v.id]} 
                        /> : "",
        hide: modo !== 'DRES',
        td: { align:'right'}, 
    },
    // {
    //     name: 'valores',
    //     field: v => valores[v.id] 
    // },
    {
        name: 'RES',
        field: v => v.reservado,
        td: { align:'right'}, 
    },
    {
        name: 'Disp/Exist',
        field: v => {
            const sel = v.inventory_sel;
            const inv = v.inventory && v.inventory[sel];
            if (inv && errores[v.id]) {
                return inv && <span style={{color: 'red'}}>{inv['dispo']} / {inv['exist']} {errores[v.id]}</span>
            } else {
                return inv && <span>{inv['dispo']} / {inv['exist']} </span>
            }
        },
        width: 110,
    },
    {
        name: 'ALM',
        field: v => {
            const options = v.inventory.map( (it,inx) => ({label: `${it['exist']} ${it['deposito_udm']} | ${it['alm']} ${it['ubi1']}/${it['ubi2']}/${it['ubi3']}`, value: inx}) );
            return <SelectField sx={{width: '100%',}} variant='standard' InputProps={{sx: {fontSize: 'inherit'}, disableUnderline: true}} defaultValue={v.inventory_sel} options={options} onChange={handleInventory(v.id)}/>
        },
        width: 100,
    },
    {
        field: v => v.udm,
        name: 'UDM',
        type: 'number',
        width: 50,
    },
])

const IndexPage = forwardRef( ({obras='', ofs='', pendiente=false, reservado=-1, isTabSelected, onReload=()=>{}}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const [search, setSearch] = useState({
        articulo:"", obras:obras, ofStatus: 'plan', of:ofs, os:"", marca:"", reserva:-1,
        color:-1, pendiente, reservado, check: true, sord: 'articulo'
    });
    const [rows, setRows] = useState([]);
    const [valores, setValores] = useState({});
    const [errores, setErrores] = useState({});
    const [modo, setModo]       = useState("ENV");
    const [fenvio, setFenvio]   = useState(moment());
    const [comprobante, setComprobante] = useState({});
    const [tot, setTot]         = useState({plan: 0, req:0, rec:0});
    const [reload, setReload]   = useState(false);
    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);

    const currentUser = useSelector(state => state.currentUser);
    const esLocalAdmin = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'inventarios-edit'])

    useEffect( () => {
        debugger
        setSearch(ss => ({...ss, obras, of: ofs, pendiente}))
    }, [obras, ofs, pendiente])

    const buildQuery = (search) => {
        const query = {...search};
        //if (query['obras']) query['obras'] = query['obras'].map(it => it.carpeta);
        if (query['ofStatus'] === "-1") delete query['ofStatus'];
        if (query['reservado'] === -1) delete query['reservado'];
        if (query['color'] === -1) delete query['color'];
        return query;
    }

    const fn = useCallback( 
        debounce(
            (search, page, perPage, enqueueSnackbar) => {
                setReload(false)
                setLoading(true)
                setErrores({})
                setValores({})
                const query = buildQuery(search);
                getAPI('/stock/workorder_materials', {...query, skip: page*perPage, limit: perPage})
                .then(data => {
                    setLoading(false);
                    data.list.forEach( it => {
                        //it.id = it.reqId;
                        //it.id = [it.articulo._id,it.articulo_color,it.of &&it.of._id].join('|');
                        it.inventory_sel = 0;
                    });
                    setNum(data.num)
                    setRows(data.list)

                    // updateDefaultValues(modo, data.list)

                    //setTot(data.totals || {plan:0})
                })
                .catch(err => {
                    setLoading(false);
                    console.error(err)
                    enqueueSnackbar(err.message, {variant: "error"})
                });    
            }, 1000),
        []
    )
    

    useEffect( () => {
        fn(search, page, perPage, enqueueSnackbar)
    }, [fn, search, page, perPage, reload, isTabSelected, enqueueSnackbar])

    // valida las transacciones que se vayan hacer no superen el limite por mismo articulo color
    const getKey = (row) => {
        return row.articulo._id + "|" + row.articulo_color;
    }
    useEffect( () => {
        if (loading) {
            return
        }
        
        const totales = {};
        rows.filter( row => valores[row.id] > 0 ).forEach(row => {
            const inv = row.inventory && row.inventory[row.inventory_sel]
            if (inv) {
                totales[getKey(row)] = totales[getKey(row)] || {tot: inv.dispo, cur: 0};

                if (modo === 'RES') {
                    totales[getKey(row)].cur += Number(valores[row.id]);
                }
                if (modo === 'ENV') {
                    totales[getKey(row)].cur += Number(valores[row.id]);
                    totales[getKey(row)].tot += row.reservado;
                }
            }
        })

        const err = {};
        rows.filter( row => valores[row.id] > 0 ).forEach(row => {
            const valor = valores[row.id];
            const tot = totales[getKey(row)];
            if (modo === 'ENV') {
                if (valor > row.pedido) {
                    err[row.id] = 'Debe ser menor a pedido'
                } else if (row.inventory && valor > row.inventory[row.inventory_sel].dispo + row.reservado) {
                    err[row.id] = 'Debe ser <= Dispo'
                }
            }

            if (modo === 'DEV') {
                if (valor > row.recibido) {
                    err[row.id] = 'Debe ser menor a recibido'
                }
            }

            if (modo === 'RES' || modo === 'ENV') {
                if (tot && (tot.cur > tot.tot)) {
                    err[row.id] = "Excede por " + (tot.cur - tot.tot);
                }
            }
        })
        setErrores(err);    
        console.log(totales, err)
    }, [loading, valores, modo, rows])

    // recalcula valores default
    useEffect( () => {
        const vv = {};
        if (modo === 'ENV') {
            rows.forEach(row => {
                const inv = row.inventory && row.inventory[row.inventory_sel];
                if (inv && row.pedido > row.recibido) {
                    if (row.reservado > 0) {
                        vv[row.id] = row.reservado;
                    } else {
                        if (inv.dispo < row.pedido - row.recibido) {
                            vv[row.id] = inv.dispo;
                        } else {
                            vv[row.id] = row.pedido - row.recibido;                        
                        }    
                    }
                }
            })
        }
        if (modo === 'DEV') {
            rows.forEach(row => {
                if (row.recibido > 0) {
                    vv[row.id] = row.recibido;
                }
            })
        }
        // este caso con el reload del search no setea los checks correspondientes
        if (false && modo === 'RES') {
            rows.forEach(row => {
                const inv = row.inventory && row.inventory[row.inventory_sel]
                if (row.reservado === 0 && inv && inv.dispo >= row.pedido - row.recibido && row.pedido > row.recibido) {
                    vv[row.id] = row.pedido - row.recibido;
                }
            })
        }
        if (false && modo === 'DRES') {
            rows.forEach(row => {
                if (row.reservado > 0) {
                    vv[row.id] = row.reservado;;
                }
            })
        }
        setValores(vv);
    }, [modo, rows])
    
    const handleSearch = fld => (ev, option) => {
        debugger
        let valor;
        switch (fld) {
            // case 'obra':
            //     valor = option;
            //     break;
            // case 'obras':
            //     valor = option;
            //     break;
            case 'check':
                valor = ev.target.checked;
                break;
            case 'pendiente':
                valor = ev.target.checked;
                break;
            default:
                valor = ev.target.value;
        }

        setSearch({...search, [fld]: valor})
    }
    
    const handleInventory = (id) => (ev) => {
        const valor = ev.target.value; 
        debugger
        const rows1 = rows.map( it => {
            if (it.id === id) {
                it.inventory_sel = valor;
            }
            return it;
        })
        setRows(rows1)
    }

    const handleValores = (row) => ev => {
        let valor;
        if (modo === 'ENV' || modo === 'DEV') {
            valor = ev.target.value;
            setValores({...valores, [row.id]: valor})
        } else {
            valor = ev.target.checked;
            if (valor) {
                setValores({...valores, [row.id]: row.pedido - row.recibido})
            } else {
                setValores({...valores, [row.id]: null})
            }
        }
    }

    const handleModo = ev => {
        const valor = ev.target.value;
        setModo(valor);
    }

    const handleTransaction = () => {
        debugger
        const movs = rows.filter( it => valores[it.id] > 0 && it.inventory[it.inventory_sel]).map(it => {
            const doc = {
                tipo: modo,
                fecha_envio: fenvio.toDate(),
                articulo: it.articulo,
                articulo_color: it.articulo_color,
                udm: it.udm,
                ubi1: it.ubi1,
                ubi2: it.ubi2,
                ubi3: it.ubi3,
                of: it.of,
                obra: it.of.obra,
                pv: it.of.pv
            }
            if (modo === 'ENV') {
                doc['recibido'] = valores[it.id]
                doc['almacen_salida'] = {_id: it.inventory[it.inventory_sel] && it.inventory[it.inventory_sel]._id}
            }
            if (modo === 'DEV') {
                doc['recibido'] = -valores[it.id]
                doc['almacen_salida'] = {_id: it.inventory[it.inventory_sel] && it.inventory[it.inventory_sel]._id}
            }
            if (modo === 'RES') {
                doc['reservado'] = valores[it.id]
                doc['almacen'] = {_id: it.inventory[it.inventory_sel] && it.inventory[it.inventory_sel]._id}
            }
            if (modo === 'DRES') {
                doc['reservado'] = -valores[it.id]
                doc['almacen'] = {_id: it.inventory[it.inventory_sel] && it.inventory[it.inventory_sel]._id}
            }
            return doc;
        })
        console.log(movs.length)
        setLoading(true);
        postAPI("/stock/workorder_materials/new/" + modo, movs).
        then(res => {
            setLoading(false);
            setReload(true);
            enqueueSnackbar("Se realizaron " + res.length + " movimientos", {variant: 'info'})

            debugger
    
            setComprobante({tipo: res[0].tipo, codigo: res[0].codigo});
            if (onReload) onReload(true);
        })
        .catch(err => {
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.message, {variant: "error"})
        });    
    }
    const handleDeleteComprobante = (ev) => {
        setComprobante("")
        setReload(true)
        if (onReload) onReload(true);
    }
    const configReport = useCallback( async () => {
        const reportConfig = {
            title: "Gestión Materiales",
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: [
                {title: 'OF/OS', field: '_', transform: (v, doc) => {
                        if (doc.of ) {
                            const of = doc.of;
                            const tipo = of.tipo === 'fabrica' ? 'OF' : 'OS'
                            return of ? `[${tipo}-${of.of}] ${of.status}` : "";
                        }
                    },
                },
                {title: 'Obra', field: '_', transform: (v, doc) => {
                        if (doc.of && doc.of.obra ) {
                            return `[${doc.of.obra.carpeta}] ${doc.of.obra.status}`;
                        }
                    },
                },
                {title: 'PV', field: '_', transform: (v, doc) => {
                        if (doc.pv) {
                            return `[${doc.pv.carpeta}] ${doc.pv.status}`;
                        }
                    },
                },
                {title: 'Categoría', field: '_', transform: (v, doc) => {
                        if (doc.articulo ) {
                            return doc.articulo.categoria;
                        }
                    },
                },
                {title: 'Marca', field: '_', transform: (v, doc) => {
                        if (doc.articulo ) {
                            return doc.articulo.marca;
                        }
                    },
                },
                {title: 'Código', field: '_', transform: (v, doc) => {
                        if (doc.articulo ) {
                            return doc.articulo.codigo;
                        }
                    },
                },
                {title: 'Descripción', field: '_', transform: (v, doc) => {
                        if (doc.articulo ) {
                            return doc.articulo.descripcion;
                        }
                    },
                },
                {title: 'Color', field: 'articulo_color'},
                {title: 'PLAN', field: 'planificado'},
                {title: 'REQ', field: 'pedido'},
                {title: 'REC', field: 'recibido'},
                {title: 'UDM', field: 'udm'},
                {title: 'Disp/Exist', field: '_', transform: (v, doc) => {
                        const sel = doc.inventory_sel || 0;
                        const inv = doc.inventory && doc.inventory[sel];
                        return inv && `${inv['dispo']} / ${inv['exist']} ${inv['deposito_udm']}`            
                    },
                },
                {title: 'Almacen', field: '_', transform: (v, doc) => {
                        const sel = doc.inventory_sel || 0;
                        const inv = doc.inventory && doc.inventory[sel];
                        return inv && `${inv['alm']} / ${inv['ubi1']}/${inv['ubi2']}/${inv['ubi3']}`            
                },
            },
        ],
            styles: {}
        }

        const ss = buildQuery(search);
        const ret = await getAPI('/stock/workorder_materials', {...ss, skip: 0, limit: EXCEL_LIMIT})
        reportConfig['rows'] = ret.list;

        return reportConfig;
    }, [search]);
    
    const extraPagination = () => {
        return esLocalAdmin && search['check'] && 
        <Stack sx={{m:0, float: 'left'}} direction='row'>
            <SelectField size='small' margin='dense' options={optionsTipoMov} value={modo} onChange={handleModo}/>
            {['ENV','DEV','RES','DRES'].indexOf(modo) > -1 && (
                <Box sx={{ml:1}}>
                    <DatePicker sx={{
                        "& .MuiOutlinedInput-root": {marginTop: '8px'},
                        "& .MuiOutlinedInput-input": {padding: '8.5px 14px'}
                        }} variant='standard' size='small' value={fenvio} onChange={v => setFenvio}/>
                    <Button 
                        sx={{m:1}} 
                        margin='dense' 
                        // size='small' 
                        variant='outlined'
                        disabled={Object.keys(valores).length === 0 || Object.keys(errores).length > 0}
                        onClick={handleTransaction}
                    >
                            GENERAR
                    </Button>
                </Box>
            )}
            { modo === 'PED' && (
                <Box>

                </Box>
            )}
            {/* <Box sx={{m:2}}>Totales</Box>
            <Box sx={{m:2}}>Planificado = {tot.plan}</Box>
            <Box sx={{m:2}}>Pedido = {tot.req}</Box>
            <Box sx={{m:2}}>Recibido = {tot.rec}</Box> */}
            {/* <Alert>
                {JSON.stringify(valores)}
            </Alert> */}
        </Stack>
    }

    return (
      <PageBase 
          paperProps={{
            width:'100%',
            height: 'calc(100% - 100px)',
            overflow:'auto'
          }} 
          title={
            <>
                <PageTitle>Gestión Materiales ({num})</PageTitle> 
                
                <SearchBoxSmall label="OF*" value={search['of']} onChange={handleSearch('of')} size='small' sx={{width:'60px', mr:2, fontSize:10}}/>
                <SearchBoxSmall label="OS*" value={search['os']} onChange={handleSearch('os')} size='small' sx={{width:'60px', mr:2}}/>
                {/* <ProjectSearchBox multiple value={search['obras']} onChange={handleSearch('obras')} sx={{width: '100px'}}/> */}
                <SearchBoxSmall label="Obras/PV" value={search['obras']} onChange={handleSearch('obras')} size='small' sx={{width:'80px', mr:2}}/>
                <SearchBoxSmall label="Marca*" value={search['marca']} onChange={handleSearch('marca')} size='small' sx={{width:'60px', mr:2}}/>
                <SearchBoxSmall label="Categoria*" value={search['categoria']} onChange={handleSearch('categoria')} size='small' sx={{width:'60px', mr:2}}/>
                <SearchBoxSmall label="Articulo*" value={search['articulo']} onChange={handleSearch('articulo')} size='small' sx={{width:'80px', mr:2}}/>
                <SearchBoxSmall label="Color" value={search['articulo_color']} onChange={handleSearch('articulo_color')} size='small' sx={{width:'80px', mr:2}}/>
                <SearchBoxSmall label="Color" select options={optionsColor} value={search['color']} onChange={handleSearch('color')} size='small' sx={{width: "80px", mr:2 }} />
                <SearchBoxSmall label="Reserva" select options={optionsReserva} value={search['reservado']} onChange={handleSearch('reservado')} size='small' sx={{width: "80px", mr:2 }} />
                <SearchBoxSmall label="OF Status" select options={optionsStatus} value={search['ofStatus']} onChange={handleSearch('ofStatus')} size='small' sx={{width: "100px", mr:2 }} />
                <SearchBoxSmall label="Orden" select options={optionsOrden} value={search['sord']} onChange={handleSearch('sord')} size='small' sx={{width: "80px", mr:2 }} />

                <SearchBoxSmall type='checkbox' label="Pendiente" checked={search['pendiente']} onChange={handleSearch('pendiente')} size='small'/>                
                {/* <SearchBoxSmall type='checkbox' label="Ver Totales" checked={search['check']} onChange={handleSearch('check')}/> */}
            </>
          }
          actions={
            <Stack direction='row'>
                <ExportButton configReport={configReport}>EXCEL</ExportButton>
            </Stack>
          }      
      >
        <>
            <Table 
                stickyHeader
                loading={loading}
                cols={columns(modo, valores, errores, handleValores, handleInventory)}
                data={rows}

                pagination
                page={page}
                rowsPerPage={perPage}
                rowCount={num}
                rowsPerPageOptions={[50,100,300]}
                onPageChange={(ev, newPage) => setPage(newPage)}
                onRowsPerPageChange={ev => setPerPage(ev.target.value)}

                extraPagination={extraPagination()}

                tableProps={{
                    size:'small', padding: 'none', stickyHeader: true
                }}
                headerProps={{
                    sx: { 
                        th: {
                            fontSize: 13, 
                            fontWeight:'bold', 
                            color:'white', 
                            backgroundColor: "#549857", //green[500], 
                            textAlign: 'center', 
                            borderRight: '1px solid #fff',
                            p: '2px 5px'
                        }
                    }
                }}
                rowProps={{
                    sx: {
                        m:1, 'td': {fontSize: '12px', p: '0px 5px'}}
                }}
            />

            { 
              comprobante && comprobante.codigo && 
                <StockMaterialDialog 
                    tipo={comprobante.tipo} 
                    comprobante={comprobante.codigo} 
                    open={!!comprobante} 
                    onCancel={v => setComprobante("")}
                    onDelete={handleDeleteComprobante}
                /> 
            }
        </>
      </PageBase>
  )
})

export default IndexPage;