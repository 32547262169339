import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { HeaderBar } from '../../components';
import PartForm from './PartForm';
import PartListPrices from './PartListPrices';
import { getAPI, checkUserRoles, useTabs } from "../../utils";
import ActivityOpenClose  from '../Activities/ActivityOpenClose';

export default function PartEditForm({docId, onReload=()=>{}}) {
  const { enqueueSnackbar } = useSnackbar();
  const [ edit, setEdit ] = useState(docId === 'new');
  const [ doc, setDoc ] = useState({});
  const [ reload, setReload ] = useState();
  const { updateCurrentTab, removeCurrentTab} = useTabs();

  const currentUser = useSelector(state => state.currentUser);
  const disabled = !checkUserRoles(currentUser, ['local-admin', 'gerencia', 'inventarios-edit'])
  const title = docId === 'new' ? "Nuevo Artículo" : (edit ? "Edición de Artículo" : "Artículo #" + doc.codigo);

  useEffect( () => {
    setReload(false);
    if ( docId === 'new' ) return 
    getAPI('/stock/parts/' + docId)
    .then(data => {
      if (data) setDoc(data)
    })
    .catch(err => {
        debugger
        console.log(err.message)
        enqueueSnackbar(err.message, {variant: "error"})
      });   
  }, [docId, reload, enqueueSnackbar])

  const handleEdit = () => {
    setEdit(!edit)
    if (edit) {
      if (docId === 'new') {
        removeCurrentTab();
      }  
    }
  }
  
  const handleClose = (doc) => {
    debugger
    setEdit(false)
    if (docId === 'new') {
      updateCurrentTab("PART")(doc)
    }
    setReload(true)
    if (onReload) onReload()
  }

  return (
    <Paper sx={{p:2}}>
      <HeaderBar 
        title={title}
        actions={
          [
            <Button autoFocus color="inherit" disabled={disabled} onClick={handleEdit}>{!edit ? "Editar" : "Cancelar"}</Button>,
            docId !== 'new' && <ActivityOpenClose sx={{float: 'right', color: '#ddd'}} app='stock' docId={docId}/>,
          ]
        }
      />
      <Box sx={{m:2}}/>

      <Box>
        
        <PartForm disabled={!edit} doc={docId === 'new' ? {_id: 'new'} : doc} onClose={handleClose} onCancel={handleEdit}/>

        {!edit && <PartListPrices docId={docId}/> }
      </Box>
    </Paper>
  )
}
