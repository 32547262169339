import React, {useState} from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const MenuOptions = ({name="", options=[]}) => {
    const [anchorEl, setAnchorel] = useState(null);

    const handleClick = event => {
        setAnchorel(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorel(null);
    };

    const handleGo = it => ev => {
        if (it.onClick)
            it.onClick(ev)
        setAnchorel(null);
    }

    return (
        <>
            <Button
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                {name}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {options.map(it => {
                    return (
                        <MenuItem onClick={handleGo(it)}>{it.label}</MenuItem>
                    )
                })}
            </Menu>
        </>
    )
}

export default MenuOptions;
