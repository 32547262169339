import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import {PageBase, PageTitle, DataGrid, SearchBox, ExportButton} from '../../components'; 
import {getAPI, debounce} from '../../utils' ;

import {moment} from 'moment';

const optionsColor = [
    {label: 'Todos',    value: -1},
    {label: 'Sin Color', value: 0},
    {label: 'Con Color', value: 1}
]

const columns = () => ([
    {
        field: 'articulo.codigo',
        headerName: 'Código',
        valueGetter: (params) => {
            const art = params.row.articulo
            if (art) return art.codigo 
            return ""
        },
        width: 150,
    },
    {
        field: 'articulo.marca',
        headerName: 'Marca',
        valueGetter: (params) => {
            const art = params.row.articulo
            if (art) return art.marca 
            return ""
        },
        width: 120,
    },
    {
        field: 'articulo.descripcion',
        headerName: 'Descripción',
        valueGetter: (params) => {
            const art = params.row.articulo
            if (art) return art.descripcion 
            return ""
        },
        width: 450,
    },
    {
        field: 'articulo_color',
        headerName: 'Color',
        type: 'string',
        width: 190,
    },
    {
        field: 'exist',
        headerName: 'Existencias',
        type: 'number',
        width: 90,
    },
    {
        field: 'reserved',
        headerName: 'Reservado',
        type: 'number',
        width: 90,
    },
    {
        field: 'almacen.codigo',
        headerName: 'Almacen',
        renderCell: (params) => {
            const art = params.row.almacen
            if (art) {
                const color = art.noneteable == 1 ? '#00f' : ''
                return <span style={{color:color}}>{art.codigo}</span>
            }
            return ""
        },
        width: 150,
    },
    {
        field: 'ubi1',
        headerName: 'Ubi1',
        type: 'number',
        width: 90,
    },
    {
        field: 'ubi2',
        headerName: 'Ubi2',
        type: 'number',
        width: 90,
    },
    {
        field: 'ubi3',
        headerName: 'Ubi3',
        type: 'number',
        width: 90,
    },
    {
        field: 'udm',
        headerName: 'UDM',
        type: 'number',
        width: 90,
    },
])

const IndexPage = forwardRef( ({}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    let [searchParams] = useSearchParams();
    const [filter, setFilter]   = useState({colorOpts: -1});
    const [reload, setReload]   = useState(false);
    const [loading, setLoading] = useState(false);
    const [num, setNum] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;

    const currentUser = useSelector(state => state.currentUser);

    const delayedFunc = useCallback( 
        debounce( ({filter, page, limit}) => {
            setLoading(true)
            const {articulo, marca, color, colorOpts, almacen} = filter;
            getAPI('/stock/inventory', {articulo, marca, color, colorOpts, almacen, skip: page*limit, limit})
            .then(data => {
                debugger
                setLoading(false);
                data.list.forEach( it => {
                    const almId = it.almacen ? it.almacen._id : "";
                    const artId = it.articulo ? it.articulo._id : "";
                    it.id = [artId, it.articulo_color, almId, it.ubi1,it.ubi2,it.ubi3].join('|')
                });
                setNum(data.num)
                setRows(data.list)
            })
            .catch(err => {
                setLoading(false);
                console.error(err)
                enqueueSnackbar(err.message, {variant: "error"})
            });
        }, 500), 
    [])

    useEffect( () => {
        debugger
        setReload(false)
        delayedFunc({filter, page, limit})
    }, [filter, page, reload, delayedFunc])

    const handleFilter = fld => ev => {
        setFilter(ll => ({...ll, [fld]: ev.target.value}))
    }
    
    const configReport = useCallback( async () => {
        const reportConfig = {
            title: `Inventarios`,
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: [
                    {title: 'Código', field: '_', transform: (v, doc) => {
                            if (doc.articulo ) {
                                return doc.articulo.codigo;
                            }
                        },
                    },
                    { title: 'Marca', field: '_', transform: (v, doc) => {
                        if (doc.articulo) {
                            return doc.articulo.marca
                        }
                    }},
                    {title: 'Descripción', field: '_', transform: (v, doc) => {
                            if (doc.articulo ) {
                                return doc.articulo.descripcion;
                            }
                        },
                    },
                    {title: 'Color', field: 'articulo_color'},
                    {title: 'Existencias', field: 'exist'},
                    {title: 'Reservado', field: 'reserved'},
                    {title: 'UDM', field: '_', transform: (v, doc) => {
                            if (doc.articulo ) {
                                return doc.articulo.deposito_udm;
                            }
                        },
                    },
                    {title: 'Almacen', field: '_', transform: (v, doc) => {
                            if (doc.almacen ) {
                                return doc.almacen.codigo;
                            }
                        } 
                    },
                    {title: 'Ubicación', field: '_', transform: (v, doc) => {
                            return `${doc.ubi1}/${doc.ubi2}/${doc.ubi3}`;
                        },
                    },
            ],
            styles: {}
        }

        const {articulo, marca, color, colorOpts, almacen} = filter;
        const data = await getAPI('/stock/inventory', {articulo, marca, color, colorOpts, almacen, limit:-1})
        reportConfig['rows'] = data.list;

        return reportConfig;
    }, [filter]);

    return (
      <PageBase 
          paperProps={{
            width:'100%',
            height: 'calc(100% - 96px)' 
            //height: 'calc(100% - 98px)'
          }} 
          title={
            <>
                <PageTitle>Inventarios ({num})</PageTitle> 
                <SearchBox label="Articulo" value={filter['articulo']} onChange={handleFilter('articulo')}/>
                <SearchBox label="Marca" value={filter['marca']} onChange={handleFilter('marca')}/>
                <SearchBox label="Color" value={filter['color']} onChange={handleFilter('color')}/>
                <SearchBox label="Color" select options={optionsColor} value={filter['colorOpts']} onChange={handleFilter('colorOpts')}/>
                <SearchBox label="Almacen" value={filter['almacen']} onChange={handleFilter('almacen')}/>
            </>
          }
          actions={
            <>
            <Stack direction='row'>
                <ExportButton configReport={configReport}>EXCEL</ExportButton>
            </Stack>
            </>
          }      
      >
          <DataGrid
              loading={loading} 
              rows={rows} 
              rowCount={num}
              columns={columns()} 

              pagination
              paginationMode="server"
              pageSize={limit}
              onPageChange={(newPage) => setPage(newPage)}
  
              rowHeight={35} 
              hideFooter={false} 
              sx={{
                  'root.border': '2px solid red',
                  'root.height': '100vh'
              }}
          />

      </PageBase>
  )
})

export default IndexPage;