import React, {useState, useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import OpportunityEdit from './OpportunityEdit';
import OpportunityShow from './OpportunityShow';
import {getAPI, checkUserRoles} from "../../utils";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  
export default function FormDialog({docId, alwaysEdit=false, onClose=()=>{}}) {
    const [edit, setEdit] = useState(alwaysEdit);
    const [doc, setDoc] = useState({})
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const title = docId === 'new' ? "Nueva Oportunidad" : (edit ? "Edición de Oportunidad" : "Oportunidad");

    useEffect( () => {
        if (!docId || docId === "new") return;
        getAPI('/crm/oppos/' + docId)
        .then(data => {
            setDoc(data);
        });
    }, [docId]);

    const currentUser = useSelector(state => state.currentUser);
    let disabled = doc && (!checkUserRoles(currentUser, ['CERRADA','PERDIDA'].indexOf(doc.status) > -1 ? 
        ['local-admin'] 
        : 
        ['local-admin', 'gerencia', 'crm-edit']))

    const handleEdit = () => {
      setEdit(!edit)
    }

    return (
      <Dialog 
        fullScreen={fullScreen}
        maxWidth="lg"
        open={true} 
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{title}</Typography>
                { docId !== 'new' && 
                  <Button autoFocus color="inherit" disabled={disabled} onClick={handleEdit}>{!edit ? "Editar" : "Cancelar"}</Button>
                }
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>

        <DialogContent sx={{padding: '0'}}>
          
          {!edit && docId !== 'new' ? 
            <OpportunityShow doc={doc} />
            :
            <OpportunityEdit 
              edit={edit || docId === 'new'}
              docId={docId} 
              onAfterSubmit={onClose}
              onCancel={onClose}
            />
          }
        </DialogContent>
      </Dialog>
  )
}
