import React, {useState} from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import UserForm from './UserForm';

import {checkUserRoles} from '../../../utils' ;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog({ editId, onClose})  {
    const [edit, setEdit] = useState(editId === 'new' ? true : false);
    const [editPass, setEditPass] = useState(false)
    // const [disabled, setDisabled] = useState(false)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const currentUser = useSelector(state => state.currentUser);
    const esAdmin = checkUserRoles(currentUser, ['local-admin','gerencia'])


    const title = editId === 'new' ? 'Nuevo Usuario' : 
      ((edit) ? 'Edición de Usuario' : 'Vista de Usuario');

    // useEffect( () => {
    //   if(editDoc && editDoc.id === '') {
    //     handleEdit()
    //   } 
    // }, [])
debugger
    const handleEdit = () => {
      debugger
      setEdit(!edit)
      if (edit) {
        setEditPass(false)
      }
    }
    const handleEditPass = () => {
      setEditPass(!editPass)
    }

    return (
      <Dialog 
        fullScreen={fullScreen}
        maxWidth="lg"
        open={true} 
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} >
            <Toolbar>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{title}</Typography>
                {esAdmin && 
                  <Button autoFocus color="inherit" onClick={handleEdit}>
                   {editId === 'new' ? "" : (!edit ? "Editar" : "Cancelar")}
                  </Button>
                }

                {edit && 
                  <Button autoFocus color="inherit" onClick={handleEditPass}>
                    CONTRASENA
                  </Button>
                }

                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close" 
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>

        <DialogContent>          
          <UserForm edit={edit} editId={editId} editPass={editPass} onClose={onClose}/>
        </DialogContent>
      </Dialog>
  )
}
