import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const StockMaterialPrint = ({doc}) => {

    return (
        <Box>
            <Typography sx={{mb:1, borderBottom: '1px solid'}} variant='h6'>Movimiento de Material {doc.tipo}/{doc.comprobante}</Typography>

            {doc.component}            
        </Box>
    )
}


const StockMaterialPrintStyled = styled(StockMaterialPrint)(({ theme }) => ({
    '.MuiTypography-h6': {
        fontSize: 17,
        fontWeight: 600,
        borderBottom: '1px solid'
    },
    "@media print": {
        "@page": {
            "size": "portrait",
            "margin": "10mm 5mm 5mm 5mm",
            "mso-header-margin":"0mm",
            "mso-footer-margin":"0mm",
            "mso-paper-source":0,
        },
        '.MuiTypography-h6': {
            fontSize: 11,
            fontWeight: 600,
            borderBottom: '1px solid'
        },
        '.MuiTypography-caption': {
            fontSize: 9
        },
        '.MuiTableContainer-root': {
            boxShadow: 0
        },
        '.MuiTable-root th': {
            fontSize: '9px',
            fontWeight: 800
        },
        '.MuiTable-root td': {
            fontSize: '8px'
        },
        '.MuiChip-label': {
            fontSize: '8px'
        }
    }
}))

export default StockMaterialPrintStyled;