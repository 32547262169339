import React, {useState, useEffect} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {PageBase, DataGrid, SearchBox, PageTitle} from '../../../components'; 
import {getAPI, deleteAPI} from '../../../utils' ;
import { useUsersChannel } from '../../Layout/WebSocketProvider';

import moment from 'moment';
import 'moment/locale/es'; // this is the important bit, you have to import the locale your'e trying to use.
moment.locale('es');

const columns = (onLogout, onDelete) => ([
    {
        field: 'id',
        headerName: 'Id',
        sortable: false,
        width: 170,
    },
    {
        field: 'initials',
        headerName: 'Nombre',
        sortable: false,
        width: 200,
    },
    {
        field: 'sessions',
        headerName: 'Sesiones',
        sortable: false,
        width: 220,
    },
    {
        field: 'activity',
        headerName: 'Actividad',
        sortable: false,
        width: 220,
    },
    {
        field: 'lastUpdate',
        headerName: 'Ultima actividad',
        sortable: false,
        width: 220,
    },
    {
        field: 'lastUpdate1',
        headerName: 'Sin actividad desde',
        valueGetter: (params) => {
            const tt = params.row['lastUpdate'];
            return moment(tt).fromNow( tt )
        },
        sortable: false,
        width: 220,
    },
    {
        field: 'actions',
        headerName: 'Acciones',
        type:'actions',
        getActions: (params) => [
          <GridActionsCellItem icon={<DeleteIcon/>} onClick={onLogout(params.row.id)} label="Logout" />,
        //   <GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params)} label="Delete" showInMenu />,
        ]
    }
])

const IndexPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    let [searchParams] = useSearchParams();
    const [filter, setFilter]   = useState(searchParams.get("filter") || "");
    const {onlineByUsers, logoutUser} = useUsersChannel();

    const [num, setNum] = useState(0);
    const [page, setPage] = useState(0);
    const limit = 100;
    
    useEffect( () => {
        setNum(Object.keys(onlineByUsers).length)
    }, [onlineByUsers])

    const handleFilter = (ev) => {
        setFilter(ev.target.value)
    }

    const handleLogout = (id) => ev => {
        logoutUser(id)
    }

    let rows = Object.values(onlineByUsers)

    console.log(rows)
    return (
        <PageBase 
            title={
                <>
                    <PageTitle>Sesiones ({num})</PageTitle> 
                    <SearchBox label="Filtro" value={filter} onChange={handleFilter}/>
                </>
            }
            actions={
                <>
                    {/* <Button sx={{m:1}} size='small' variant='outlined' onClick={handleNew}>Nuevo</Button> */}
                </>
            }       
       >
            <DataGrid
                rows={rows} 
                rowCount={num}
                columns={columns(handleLogout)} 

                pagination
                paginationMode="server"
                pageSize={limit}
                onPageChange={(newPage) => setPage(newPage)}
    
                rowHeight={35} 
                hideFooter={false} 
                sx={{
                    'root.border': '2px solid red',
                    'root.height': '100vh'
                }}
              
            />    

        </PageBase>
    )
}

export default IndexPage;