import React from 'react'
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';

import { AutocompleteBase, AutocompleteObjectInput } from '../../components'

const PriceListAutocomplete = ({proveeId, tipo="1", helperText, onInputChange, onClick=()=>{}, ...props}) => {

    const codigo = (it) => {
        if (!it || !it.articulo) return "";
        return `[${it.articulo.codigo}] ${it.articulo.descripcion} / ${it.provee.fullname}`;
    }
    const getOptionLabel = (it) => {
        return codigo(it);
    }
    const isOptionEqualToValue = (option, value) => {
        return codigo(option) === codigo(value)
        return option && value && option._id === value._id
    }
    return (
        <Box>
            <AutocompleteBase
                sx={{width: '100%', display: 'inline-block'}}
                label={"Lista Precios" + (tipo === 1 ? "Articulo" : "Servicio")}

                autocomplete_method={'/stock/price_list/'+ proveeId + '/autocomplete'}
                autocomplete_params={{tipo}}
                getOptionLabel={getOptionLabel}
                renderOption={(props, option) => <li {...props}>{codigo(option)}</li> }
                isOptionEqualToValue={isOptionEqualToValue}
                disableClearable={false}
                errorText={helperText}
                onInputChange={onInputChange}
                {...props}
            />
        </Box>
    )
}

export default PriceListAutocomplete;
    
export const PriceListAutocompleteObjectInput = ({...other}) => {
    return (
        <AutocompleteObjectInput 
            {...other} 
            Component={PriceListAutocomplete}
        />
    )
}
